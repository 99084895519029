export const ellipsePlayBtn = require("../assets/Ellipse.png");
export const polygonPlayBtn = require("../assets/Polygon.png");
export const mainLandingBg = require("../assets/MainlandingBg.svg");
export const mainLandingBgMobile = require("../assets/MainlandingBgMobile.svg");
export const buyerLogo = require("../assets/Buyer.svg");
export const sellerLogo = require("../assets/Seller.svg");
export const platformLogo = require("../assets/Platform.svg");
export const companyLogo = require("../assets/Heroimage.svg");
export const targetLogo = require("../assets/Target.svg");
export const unlocklogo = require("../assets/Unlock.svg");
export const arrowListIcon = require("../assets/Vector.svg");
export const healthCareLogo = require("../assets/HealthcareNew.svg");
export const healthLogo = require("../assets/healthLogo.svg");
export const businessLogo = require("../assets/Business.svg");
export const rocketIcon = require("../assets/Rocket.svg");
export const bookMark = require("../assets/Bookmark.svg");
export const flag = require("../assets/Flag.svg");
export const share = require("../assets/Share.svg");
export const info = require("../assets/Info.svg");
export const communityLogo = require("../assets/CommunityLogo.svg");
export const foldedArrow = require("../assets/FoldedArrow.svg");
export const namiLogo = require("../assets/NamiLogo.svg");
export const revenueLogo = require("../assets/Revenue.svg");
export const profitLogo = require("../assets/Profit.svg");
export const mapLogo = require("../assets/Map.svg");
export const employeesLogo = require("../assets/Employees.svg");
export const calendarLogo = require("../assets/Calendar.svg");
export const valuationLogo = require("../assets/Valuation.svg");
export const mobileIcon = require("../assets/MobileIcon.svg");
export const arrowBg = require("../assets/arrow.svg");
export const Figma = require("../assets/Figma.gif");
export const heroAnimated = require("../assets/Hero_ani.gif");
export const Purpleline = require("../assets/P_SVG.svg");
export const Greenline = require("../assets/G_SVG.svg");
export const MobilePurple = require("../assets/MobilePurple.svg");
export const MobileGreen = require("../assets/MobileGreen.svg");
export const saasLogo = require("../assets/saasLogo.svg");
export const phoneLogo = require("../assets/phoneLogo.svg");
export const eCommerceLogo = require("../assets/eCommerceLogo.svg");
export const energyLogo = require("../assets/energyLogo.svg");
export const startupLogo = require("../assets/startupLogo.svg");
export const profile1 = require("../assets/profile1.png");
export const profile2 = require("../assets/profile2.png");
export const profile1Gray = require("../assets/profile1Gray.png");
export const profile2Gray = require("../assets/profile2Gray.png");
export const verifiedMark = require("../assets/verifiedMark.svg");
export const whatPeopleSay = require("../assets/whatPeopleSay.png");
export const arrowDownIcon = require("../assets/arrowDownIcon.svg");
export const UKFlag = require("../assets/UKFlag.svg");
export const ArFlag = require("../assets/arabic.svg");
export const arabicLogo = require("../assets/arabiclogo.svg");