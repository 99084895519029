import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import TranslateText from "../../../components/src/GoogleTranslateAPI";
import { getStorageData, setStorageData } from "framework/src/Utilities";

interface FeedbackInterface {
  attributes: {
    user_feedback: string;
    star_rating: number;
    account: {
      user_type: string;
      first_name: string;
      last_name: string;
      image: {
        image: string
      }
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  hoveredWhatPeopleSayCard: number | null;
  language: string;
  pageTxtData: any;
  feedbackList: FeedbackInterface[];
  buyerLink: string;
  sellerLink: string;
  showViewMoreBtn: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MainLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createTrafficSourcesCallID: string = "";
  getFeedbackApiCallId: string = "";
  getSignupLinksApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      hoveredWhatPeopleSayCard: null,
      language: "en",
      showViewMoreBtn: false,
      buyerLink:'',
      sellerLink: '',
      pageTxtData: {
        buyerTxt: configJSON.buyerTxt,
        sellerTxt: configJSON.sellerTxt,
        signInTxt: configJSON.signIn,
        sectionOneHeading: configJSON.sectionOneHeading,
        sectionOneContent: configJSON.sectionOneContent,
        becomeABuyer: configJSON.becomeABuyer,
        becomeASeller: configJSON.becomeASeller,
        startUpTxt: configJSON.startUpTxt,
        healthCareTxt: configJSON.healthCareTxt,
        businessTxt: configJSON.businessTxt,
        energyTxt: configJSON.energyTxt,
        eCommerceTxt: configJSON.eCommerceTxt,
        mobileAppTxt: configJSON.mobileAppTxt,
        askingPrTxt: configJSON.askingPrTxt,
        mnTxt: configJSON.mnTxt,
        sarTxt: configJSON.sarTxt,
        liveChatTxt: configJSON.liveChatTxt,
        exampleNameTxt: configJSON.exampleNameTxt,
        verifiedTxt: configJSON.verifiedTxt,
        buyerProfileTxt: configJSON.buyerProfileTxt,
        privateEqTxt: configJSON.privateEqTxt,
        valuationRgTxt: configJSON.valuationRgTxt,
        exampleRg: configJSON.exampleRg,
        buyerInt: configJSON.buyerInt,
        anonymousTitle: configJSON.anonymousTitle,
        platformTxt: configJSON.platformTxt,
        cardContent1: configJSON.cardContent1,
        cardContent2: configJSON.cardContent2,
        cardContent3: configJSON.cardContent3,
        howNamiWorks: configJSON.howNamiWorks,
        unlockTitle: configJSON.unlockTitle,
        unlockSubTitle: configJSON.unlockSubTitle,
        broadReach: configJSON.broadReach,
        broadReachContent: configJSON.broadReachContent,
        simpleListing: configJSON.simpleListing,
        simpleListingContent: configJSON.simpleListingContent,
        dataProtection: configJSON.dataProtection,
        dataProtectionContent: configJSON.dataProtectionContent,
        targetTitle: configJSON.targetTitle,
        targetSubTitle: configJSON.targetSubTitle,
        diverseChoices: configJSON.diverseChoices,
        diversechoicesContent: configJSON.diversechoicesContent,
        smoothJourney: configJSON.smoothJourney,
        smoothJourneyContent: configJSON.smoothJourneyContent,
        tailoredMatches: configJSON.tailoredMatches,
        tailordedContent: configJSON.tailordedContent,
        preScreenedtitle: configJSON.preScreenedtitle,
        preScreenedSubtitle: configJSON.preScreenedSubtitle,
        whatPeopleSayTitle: configJSON.whatPeopleSayTitle,
        whatPeopleSayName: configJSON.whatPeopleSayName,
        oneYearWithUs: configJSON.oneYearWithUs,
        whatPeopleSayTextHovered: configJSON.whatPeopleSayTextHovered,
        whatPeopleSayText: configJSON.whatPeopleSayText,
        communityTitle: configJSON.communityTitle,
        signUpNow: configJSON.signUpNow,
        saasTitle: configJSON.saasTitle,
        annualRevenue: configJSON.annualRevenue,
        examplePrice: configJSON.examplePrice,
        annualProfit: configJSON.annualProfit,
        examplePrice2: configJSON.examplePrice2,
        basedIn: configJSON.basedIn,
        jeddahTxt: configJSON.jeddahTxt,
        numberEmployee: configJSON.numberEmployee,
        foundeIn: configJSON.foundeIn,
        valuation: configJSON.valuation,
        exampleprice3: configJSON.exampleprice3,
        examplePE: configJSON.examplePE,
        arabicLine: configJSON.arabicLine,
        mobileContent: configJSON.mobileContent,
        examplePrice4: configJSON.examplePrice4,
        examplePrice5: configJSON.examplePrice5,
        examplePrice6: configJSON.examplePrice6,
        riyadhTxt: configJSON.riyadhTxt,
        exampleEVRe: configJSON.exampleEVRe,
        viewMore: configJSON.viewMore
      },
      feedbackList: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("token");
    let isFeedbackGiven = await getStorageData("feedback_given");
    if(isFeedbackGiven != 'true') {
      this.setState({showViewMoreBtn: true})
    }

    // Customizable Area Start
    const params = new URLSearchParams(window.location.search);
    const platformParam = params.get("platform");
    const campaignParam = params.get("campaign");
    try {
      if (platformParam && campaignParam) {
        // Both parameters are present, call the  API
        await this.trafficSourcesSubmit(platformParam, campaignParam);
      }
    } catch (error) {
    } finally {
      // Remove parameters from URL after API call (whether successful or failed)
      params.delete("platform");
      params.delete("campaign");

      const newUrl = window.location.pathname;
      window.history.replaceState({}, "", newUrl);
    }
    this.getSignupLinks();
    this.getLanguage()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseAPIId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (this.getFeedbackApiCallId === responseAPIId && responseJson.data) {
      let buyerFeed = responseJson.data.find((tempObj: FeedbackInterface) => tempObj.attributes.star_rating > 4 && ['تاجر', 'buyer'].includes(tempObj.attributes.account.user_type));
      let sellerFeed = responseJson.data.find((tempObj: FeedbackInterface) => tempObj.attributes.star_rating > 4 && ['مشتر', 'seller'].includes(tempObj.attributes.account.user_type));

      let tempList = [buyerFeed, sellerFeed];
      tempList = tempList.filter(tempObj => tempObj != null)
      this.setState({feedbackList: tempList})
    }
    if (this.getSignupLinksApiCallId === responseAPIId && responseJson.length) {
      this.setState({
        buyerLink: responseJson[0].buyer_link,
        sellerLink: responseJson[0].seller_link
      })
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  navigateToRegistration = (value: string) => {
    const SigninMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    SigninMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      value
    );
    SigninMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage(SigninMessage.id, SigninMessage);
  };

  navigateToFeedback = () => {
    const navMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Feedback"
    );
    navMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage(navMessage.id, navMessage);
  };

  handleLangChange = (e: any) => {
    this.setState({ language: e.target.value as string })
    setStorageData('language', e.target.value)
    window.location.reload();
  }

  getLanguage = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      return this.setState({ language: lang }, () => {
        this.applyTranslation()
        this.getFeedbacks()
      })
    }
    this.setState({ language: 'en' }, () => this.getFeedbacks())
  }

  getFeedbacks = () => {
    const header = {
      "Content-Type": configJSON.contentType,
    };

    let endPoint = `${configJSON.getFeedbackEndpoint}?locale=${this.state.language}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFeedbackApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getSignupLinks = () => {
    const header = {
      "Content-Type": configJSON.contentType,
    };

    let endPoint = `${configJSON.getSignupLinksEndpoint}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSignupLinksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  applyTranslation = async () => {
    let translatedObj = await TranslateText(this.state.pageTxtData, this.state.language, 'en')
    this.setState({ pageTxtData: translatedObj });
  }

  handleHoverOnWhatPeopleSayCard = (cardNumber: number) => {
    this.setState({ hoveredWhatPeopleSayCard: cardNumber });
  };

  handleLeaveOnWhatPeopleSayCard = () => {
    this.setState({ hoveredWhatPeopleSayCard: null });
  };

  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }

  trafficSourcesSubmit = async (platform: string, campaign: string) => {
    const endpoint = `${configJSON.trafficSourcesEndPoint
      }?platform=${platform}&campaign=${campaign}`;
    const headers = { "content-type": "application/json" };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
    this.createTrafficSourcesCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
