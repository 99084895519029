// Customizable Area Start
import React from "react";

import { Container, Box } from "@material-ui/core";

import CompanytypeController, { Props } from "./CompanyTypeController";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { backArrow, dot, frontArrow } from "./assets";
import {
  styles,
  Top_heading,
  TypographyCategory,
  CustomizeButton,
  CustomizableImage,
  ButtonTypography,
  DotImg,
  openBox
} from "./Categoriessubcategories.web";

export default class Companytype extends CompanytypeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderDisabledButton = () => {
    if (this.state.openStartUpBox || this.state.openBuisnessBox) {
      return false;
    } else {
      return true;
    }
  };
  renderStartUpDotImage = () => {
    if (this.state.openStartUpBox) {
      return <DotImg src={dot} />;
    }
  };
  renderBuisnessDotImage = () => {
    if (this.state.openBuisnessBox) {
      return <DotImg src={dot} />;
    }
  };

  render() {
    return (
      <>
        <HeaderBlock />
        <div className="mainlogin">
          <Box className="contain" style={styles.backGroundContain}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box sx={styles.headerLinks}>
                <Box
                  sx={{ ...styles.headerLinksContent }}
                  data-test-id="goBackBtn"
                  onClick={() => this.handlegoBackSubCatgories()}
                >
                  <CustomizableImage
                    src={this.state.language === "en" ? backArrow : frontArrow}
                  />
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px"
                    }}
                  >
                    <Box sx={styles.links}>
                      {this.getContent(this.state.pageTextData.previous,this.state.pageTextData.previousArabic)}
                    </Box>
                    <Box sx={styles.linksData}>
                      {this.getContent(this.state.pageTextData.typeOfRole,this.state.pageTextData.typeOfRoleArabic)}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Container style={styles.container}>
                <Box style={styles.containerBox}>
                  <Box sx={styles.headingBox}>
                    <Top_heading>
                      {this.getContent(this.state.pageTextData.isYourCompany,this.state.pageTextData.isYourCompanyArabic)}
                    </Top_heading>
                  </Box>
                  <Box sx={styles.box18}>
                    <Box
                      sx={{
                        border: this.state.openStartUpBox
                          ? "4px solid #5412FC"
                          : "2px solid #8C8C8C",
                        ...(!this.state.openStartUpBox
                          ? styles.box19
                          : styles.box20)
                      }}
                      className="box19"
                      data-test-id="startUpBox"
                      onClick={() => this.handleStartUpBox()}
                    >
                      <div>{this.renderStartUpDotImage()}</div>
                      <div
                        style={{
                          width: "100%",
                          height: this.state.openStartUpBox ? "70%" : "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <TypographyCategory
                          style={{
                            color: this.state.openStartUpBox
                              ? "rgba(84, 18, 252, 0.80)"
                              : ""
                          }}
                          className="boxText"
                        >
                          {this.getContent(this.state.pageTextData.startUp,this.state.pageTextData.staetUpArabic)}
                        </TypographyCategory>
                      </div>
                    </Box>
                    {
                      <Box
                        sx={{
                          border: this.state.openBuisnessBox
                            ? "4px solid #5412FC"
                            : "2px solid #8C8C8C",
                          ...(!this.state.openBuisnessBox
                            ? styles.box19
                            : styles.box20)
                        }}
                        data-test-id="businessBox"
                        onClick={this.handleBuisnessBox}
                      >
                        {this.renderBuisnessDotImage()}
                        <div
                          style={{
                            width: "100%",
                            height: this.state.openBuisnessBox ? "70%" : "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex"
                          }}
                        >
                          <TypographyCategory
                            style={{
                              color: this.state.openBuisnessBox
                                ? "rgba(84, 18, 252, 0.80)"
                                : ""
                            }}
                            className="boxText"
                          >
                            {this.getContent(this.state.pageTextData.business,this.state.pageTextData.businessArabic)}
                          </TypographyCategory>
                        </div>
                      </Box>
                    }
                  </Box>
                  {openBox(
                    this.state.openStartUpBox,
                    this.state.boxTitle,
                    this.state.openBuisnessBox,
                    this.state.boxContent
                  )}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "60px"
                    }}
                  >
                    <CustomizeButton
                      data-test-id="nextBtn"
                      disabled={this.renderDisabledButton()}
                      onClick={() => this.handleCompanyTypeSelection()}
                      style={{
                        background:
                          this.state.openBuisnessBox ||
                          this.state.openStartUpBox
                            ? "#10BFB8"
                            : "#8C8C8C"
                      }}
                      variant="contained"
                    >
                      <ButtonTypography
                        style={styles.buttonContent}
                        data-test-id="next-button"
                      >
                        {this.getContent(this.state.pageTextData.next,this.state.pageTextData.nextArabic)}
                      </ButtonTypography>
                    </CustomizeButton>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </div>
        <FooterBlock />
      </>
    );
  }
}

// Customizable Area End
