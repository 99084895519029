import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Slider as RangeSlider,
  TextField,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { oneListingIcon, threeListingIcon } from "./assets";
import FooterBlock from "../../../components/src/FooterBlock.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import Slider from "react-slick";
import { CountryOption, configJSON } from "./CatalogueController";
import CatalogueList from "./CatalogueList.web";
import { Chart } from "react-google-charts";
import Autocomplete from "@material-ui/lab/Autocomplete";
export const webConfigJSON = require("../../user-profile-basic/src/config.js");
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  listingsCardsStyle = () => {
    return {
      overflow: "auto",
      "& .inner-box": {
        display: "flex",
        flexWrap: "wrap",
        width: "1320px",
        margin: "1% auto",
        justifyContent: "center",
      },
      "@media (max-width:600px)": {
        width: "964px",
        display: "none",
      },
    };
  };

  listingsCardsMobileStyle = () => {
    return {
      display: "none",
      margin: "36px 0 !important",
      "& .listing-slider": {
        width: "100% !important",
        "& .slick-list": {
          marginBottom: "30px !important",
          padding: "0 11px !important",
          "& .slick-track": {
            width: "950px !important",
            marginLeft: "40px",
            "& .slick-slide": {
              height: "auto",
            },
          },
        },
        "& .slick-dots": {
          display: "flex",
          justifyContent: "center",
          margin: 0,
          padding: "0rem 0",
          listStyleType: "none",
          bottom: "-17px",
          "& li": {
            margin: "0 4px",
            width: "auto",
            height: "auto",
          },
          "& button": {
            display: "block",
            width: "7px",
            height: "7px",
            padding: 0,
            border: "none",
            borderRadius: "100%",
            backgroundColor: "#D9D9D9",
            textIndent: "-9999px",
          },
          "& li.slick-active button": {
            backgroundColor: "black",
          },
        },
      },
      "@media (max-width:600px)": {
        display: "block",
      },
    };
  };

  filterMsgStyle = () => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "60px",
      "& label": {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "28px",
        color: "#000",
      },
    };
  };

  filterMenuStyle = () => {
    return {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      borderBottom: "1px #262626 solid",
      "& .filter-menu-list": {
        width: "70%",
        padding: "0px 15px",
        borderRight: "1px #262626 solid",
        boxSizing: "border-box",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "& .filter-menu-box": {
          display: "flex",
          justifyContent: "space-between",
          overflow: "auto",
          padding: "20px 0px",
          gap:"10px",
          alignItems: "center",
          boxSizing: "border-box",
          "&::-webkit-scrollbar": {
            width: "45px",
            height: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "darkgrey",
          },
          "& .filter-menu": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minWidth: "150px",
            border: "1px solid #262626",
            borderRadius: "12px",
            padding: "10px 15px",
            cursor: "pointer",
            boxSizing: "border-box",
            "& .menu-text": {
              cursor: "pointer",
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "20px",
              color: "#000",
            },
          },
          "& .clear-filter-menu": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minWidth: "128px",
            border: "1px solid #FF7575",
            borderRadius: "12px",
            padding: "11px 16px",
            cursor: "pointer",
            "& .menu-text": {
              cursor: "pointer",
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "20px",
              color: "#FF7575",
            },
          },
        },
      },
      "& .search-box": {
        padding: "15px",
        display: "flex",
        justifyContent: "center",
        gap: "12px",
        alignItems: "center",
        boxSizing: "border-box",
        width: "30%",
        "& .searchTextBox": {
          width: "300px",
          display: "flex"
        },
        "& .text-box": {
          border: "1px #262626 solid",
          borderRadius: "24px",
          padding: "14px 36px",
          width: "100%",
          color: "#000",
          fontSize: "20px",
          fontFamily: "Montserrat",
          fontWeight: 400,
          lineHeight: "24px",
          height:"48px",
          boxSizing:"border-box",
          wordWrap: "break-word",
          "&::placeholder": {
            color: "#8C8C8C",
            fontStyle: "italic",
          },
        },
        "& .search-icon-box": {
          borderRadius: "50%",
          display:"flex",
          alignItems: "center",
          justifyContent: "center",
          border: "3px #10BFB8 solid",
          padding: "10px",
          width:"48px",
          height:"48px",
          boxSizing: "border-box",
          "& .icon": {
            width: "100%",
            height: "100%"
          },
        },
      },
      "@media (max-width:959px)": {
        display: "block",
        "& .filter-menu-list": {
          width: "100%",
        },
        "& .search-box": {
          paddingTop: "0px",
          width: "100%",
          "& .searchTextBox": {
            width: "100% !important"
          },
          "& .text-box": {
            width: "100% !important",
          },
        },
      },
      "@media (max-width:600px)": {
        "& .filter-menu-list": {
          padding: "24px 24px 17px",
          borderRight: "none",
          "& .filter-menu-box": {
            gap: "12px !important",
            "& .filter-menu": {
              minWidth: "110px !important",
              borderRadius: "8px !important",
              padding: "7px 11px !important",
              "& .menu-text": {
                fontSize: "13px !important",
                lineHeight: "24px",
              },
            },
          },
        },
        "& .search-box": {
          padding: "0 24px 24px",
          gap: "10px",
          "& .text-box": {
            padding: "9px 22px !important",
            fontSize: "14px !important",
          },
          "& .search-icon-box": {
            border: "2px #10BFB8 solid !important",
            padding: "9px !important",
            "& .icon": {
              width: "18px !important",
              height: "18px !important",
            },
          },
        },
      },
    };
  };

  settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesPerRow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: false,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    centerMode: true,
    variableWidth: true,
  };

  viewOptionMenuStyle = () => {
    return {
      display: "flex",
      justifyContent: "space-between",
      gap: "24px",
      padding: "16px 20px 28px 24px",
      cursor: "pointer",
      "& *": {
        cursor: "pointer",
      },
      "&.first": {
        alignItems: "flex-start",
        padding: "24px 20px 0px 24px",
      },
      "& .view-options": {
        display: "flex",
        alignItems: "center",
        flex: 1,
        padding: "0 56px",
        "&.first": {
          padding: "25px 64px 40px",
          borderBottom: "1px solid #8C8C8C",
        },
        "& .text": {
          color: "black",
          fontSize: "20px !important",
          fontFamily: "Montserrat",
          fontWeight: 500,
          lineHeight: "24px",
          wordWrap: "break-word",
        },
      },
      "@media (max-width:600px)": {
        gap: "20px",
        "& .view-options": {
          padding: "0 23px",
          "&.first": {
            padding: "25px 30px 40px !important",
          },
        },
      },
    };
  };

  typeOptionMenuStyle = () => {
    return {
      padding: "0 16px !important",
      background: "#fff",
      borderRadius: "20px 0 0 20px",
      maxHeight: "360px",
      overflow: "auto",
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-track": {
        background: "white",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D9D9D9",
        borderRadius: "5px",
        border: "3px solid white",
      },
      '& [data-test="filter-type"]': {
        cursor: "pointer",
      },
      "& .MuiFormControlLabel-root": {
        margin: "0",
        cursor: "context-menu",
        padding: "30px 9px",
        borderBottom: "1px #8C8C8C solid",
        minWidth: "187px",
        "&:last-child": {
          borderBottom: "none",
        },
        "& .MuiRadio-colorSecondary": {
          padding: "0",
          color: "#5412FC",
          marginRight: "15px",
          "& .MuiSvgIcon-root": {
            fontSize: "1.8rem",
          },
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
          color: "#5412FC",
        },
        "& .MuiTypography-root": {
          color: "black",
          fontSize: "20px !important",
          fontFamily: "Montserrat",
          fontWeight: 500,
          lineHeight: "24px",
          wordWrap: "break-word",
          "&.selected": {
            fontWeight: 700,
          },
        },
      },
      "@media (max-width:759px)": {
        borderRadius: "20px 20px 0 0",
      },
    };
  };

  typeOptionSubCategoryStyle = (styleColumnCount: number) => {
    return {
      overflow: "auto",
      backgroundColor: "#fff",
      maxHeight: "250px",
      columnCount: styleColumnCount,
      columnGap: "20px",
      borderRadius: "0 20px 20px 0",
      borderLeft: "1px #8C8C8C solid",
      padding: "20px",
      "&::-webkit-scrollbar": {
        width: "12px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
        marginRight: "12px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D9D9D9",
        borderRadius: "10px",
        border: "4px solid white",
      },
      "& .sub-category-name": {
        cursor: "context-menu",
        marginBottom: "28px",
        width: "230px",
        padding: "0",
        alignItems: "center",
        "& *": {
          cursor: "pointer",
        },
        "& .MuiListItemIcon-root": {
          minWidth: "auto",
          "& .MuiCheckbox-root": {
            padding: "0",
            margin: "0",
          },
          "& .MuiCheckbox-colorSecondary.Mui-checked": {
            color: "#5412FC !important",
          },
        },
        "& .MuiListItemText-root": {
          "& .MuiTypography-root": {
            color: "black",
            paddingLeft: "12px",
            fontSize: "16px",
            fontFamily: "Montserrat",
            fontWeight: 500,
            lineHeight: "24px",
            wordWrap: "break-word",
            whiteSpace: "break-spaces",
          },
        },
      },
      "@media (max-width:1200px)": {
        columnCount: styleColumnCount > 2 ? 2 : styleColumnCount,
      },
      "@media (max-width:759px)": {
        maxHeight: "200px",
        borderRadius: "0 0 20px 20px",
        borderLeft: "none",
        borderTop: "1px #8C8C8C solid",
        "& .sub-category-name": {
          width: "140px",
        },
        "&::-webkit-scrollbar-track": {
          margin: "0 12px",
        },
      },
    };
  };

  priceContainer = () => {
    return {
      padding: "1.5rem",
      maxWidth: "544px",
      maxHeight: "70vh",
      overflow: "auto",
      boxSizing: "border-box",
      background: "#fff",
      "& .listPriceRange": {
        paddingTop: "0",
        paddingBottom: "0",
        margin: "20px 0px",
        paddingLeft: "18px",
        paddingRight: "18px",
        cursor: "pointer",
        "& *": {
          cursor: "pointer",
          fontFamily: "Montserrat",
        },
        "& .MuiListItemText-dense *": {
          color: "#000",
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "24px",
        },
        "& .MuiListItemIcon-root": {
          minWidth: "40px",
          "& .MuiIconButton-edgeStart": {
            marginLeft: "0px",
            padding: "0px",
          },
        },
      },
      "& .locationBlock": {
        marginBottom: "1rem",
        "& .MuiAutocomplete-root": {
          width: "80% !important",
          "& .MuiInputBase-root": {
            marginLeft: "0px",
            "& input": {
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: "500",
              "&::placeholder": {
                color: "#8C8C8C",
                fontStyle: "italic",
                fontWeight: "400",
              },
            },
            "& fieldset": {
              borderRadius: "12px",
              border: "1px solid #8c8c8c",
            },
          },
        },
      },
      "& .filterRangeBlock": {
        marginBottom: "1rem",
        borderBottom: "1px solid #8C8C8C",
        paddingBottom: "20px",
        "&:last-child": {
          marginBottom: "0px",
          borderBottom: "0px",
        },
      },
      "& .barChart": {
        marginVertical: 20,
        borderRadius: 16,
        flex: 1,
      },
      "& .menuTitle": {
        fontSize: "20px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        color: "#000",
        lineHeight: "24px",
        marginBottom: "1rem",
        display: "block",
      },
      "& .rowBoxNRange": {
        display: "flex",
        gap: "20px",
        alignItems: "center",
        marginTop: "1rem",
      },
      "& .MuiSlider-root": {
        color: "#5412FC",
        height: "4px",
        margin: "auto",
        width: "calc(100% - 18px)",
        display: "block",
      },
      "& .MuiSlider-thumb": {
        width: "20px",
        height: "20px",
        marginTop: "-8px",
      },
      "& .MuiSlider-rail": {
        height: "4px",
      },
      "& .MuiSlider-track": {
        height: "4px",
      },

      "& .mark-list": {
        position: "relative",
        width: "32px",
        "& span": {
          content: "",
          width: "18px",
          fontSize: 0,
          background: "#8c8c8c",
          height: "2px",
          position: "absolute",
          margin: "auto",
          right: 0,
          left: "0",
          bottom: "-30px",
          top: 0,
        },
      },
      "& .formInput": {
        "& label": {
          fontSize: "16px",
          fontFamily: "Montserrat",
          fontWeight: 500,
          lineHeight: "24px",
          color: "#000",
          marginBottom: "16px",
        },
        "& input": {
          fontSize: "16px",
          fontFamily: "Montserrat",
        },
        "& div > div": {
          margin: "0px",
        },
        "& fieldset": {
          border: "1px solid #8C8C8C",
          borderRadius: "12px",
        },
      },
    };
  };

  listBox = () => {
    return {
      display: "flex",
      alignItems: "center",
      gap: "35px",
      flexDirection: "column",
      justifyContent: "center",
      padding: "40px",
      margin: "auto",
      "@media(max-width: 767px)": {
        padding: "20px"
      },
      "@media(max-width: 380px)": {
        padding: "15px"
      },
    };
  };

  sortByOptionMenuStyle = () => {
    return {
      padding: "1.5rem !important",
      width: "316px",
      "& .MuiFormControlLabel-root": {
        margin: "12px 0px",
        padding: "0px",
        "& .MuiRadio-colorSecondary": {
          padding: "0",
          color: "#5412FC",
          marginRight: "27px",
          "& .MuiSvgIcon-root": {
            fontSize: "2rem",
          },
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
          color: "#5412FC",
        },
        "& .MuiTypography-root": {
          color: "#262626",
          fontSize: "20px !important",
          fontFamily: "Montserrat",
          fontWeight: 500,
          lineHeight: "24px",
          wordWrap: "break-word",
        },
      },
      "@media (max-width:600px)": {
        width: "auto",
      },
    };
  };

  getFilterMenuOption = (option: string) => {
    const { pageTxtData } = this.state
    let subCategoriesList: any[] = [];
    if (!!this.state.filterType) {
      const subCategoryList = this.state.categoriesList.filter(categories => categories.id === this.state.filterType);
      subCategoriesList = subCategoryList.length ? subCategoryList[0].subCategories : [];
    }

    const styleColumnCount = Math.ceil((subCategoriesList.length + 1) / 4) > 4 ? 4 : Math.ceil((subCategoriesList.length + 1) / 4);
    if(option === configJSON.type || option === configJSON.typeAr) {
      return (
        <>
          <MenuItem disableRipple>
            <Box sx={this.typeOptionMenuStyle()}>
              <RadioGroup
                data-test="filter-type"
                aria-label="type"
                name="filter-type"
                value={this.state.filterType}
                onChange={this.handleFilterTypeMenu}
              >
                {this.state.categoriesList.map((categories) => (
                    <React.Fragment key={categories.id}>
                      <FormControlLabel
                        value={categories.id}
                        control={<Radio />}
                      label={this.getLabel(categories.name)}
                        classes={{
                          label:
                            categories.id === this.state.filterType
                              ? "selected"
                              : "",
                        }}
                      />
                    </React.Fragment>
                  ))}
              </RadioGroup>
            </Box>
            {subCategoriesList.length ? (
              <Box
                sx={this.typeOptionSubCategoryStyle(styleColumnCount)}
                data-test="sub-category-list"
              >
                <ListItem
                  role={undefined}
                  dense
                  disableRipple
                  button
                  data-test="sub-category-all-select"
                  onClick={this.handleFilterAllSubCategory}
                  className="sub-category-name"
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      data-test="sub-category-all-select-checkbox"
                      checked={subCategoriesList.every(
                        (sub) => sub.isChecked
                      )}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": "All" }}
                    />
                  </ListItemIcon>
                  <ListItemText id={"All"} primary={this.getSpecifiedLangString(this.state.language, "Select All", configJSON.selectAllTxt)} />
                </ListItem>
                {subCategoriesList.map((subCategory, i) => (
                  <ListItem
                    key={`${subCategory.id}_${i}`}
                    role={undefined}
                    disableRipple
                    dense
                    button
                    data-test="sub-category-list-item"
                    onClick={() => this.handleFilterSubCategory(i)}
                    className="sub-category-name"
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={subCategory.isChecked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": `${subCategory.id}_${i}`,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={`${subCategory.id}_${i}`}
                      primary={subCategory.name}
                    />
                  </ListItem>
                ))}
              </Box>
            ) : null}
          </MenuItem>
        </>
      );
    } else if(option === configJSON.view || option === configJSON.viewAr) {
      return (
        <>
          <MenuItem
            disableRipple
            data-test="dropdown-option"
            onClick={() => this.handleDropdownSelect("1")}
          >
            <Box sx={this.viewOptionMenuStyle()} className="first">
              <img src={oneListingIcon} alt="1" />
              <Box className="view-options first">
                <Typography className="text">{this.getSpecifiedLangString(this.state.language, pageTxtData.oneListing, configJSON.oneListingArabic)}</Typography>
              </Box>
            </Box>
          </MenuItem>
          <MenuItem
            disableRipple
            data-test="dropdown-option"
            onClick={() => this.handleDropdownSelect("3")}
          >
            <Box sx={this.viewOptionMenuStyle()}>
              <img src={threeListingIcon} alt="1" />
              <Box className="view-options">
                <Typography className="text">{this.getSpecifiedLangString(this.state.language, pageTxtData.threeListing, configJSON.threeListingArabic)}</Typography>
              </Box>
            </Box>
          </MenuItem>
        </>
      );
    } else if(option === configJSON.allFilters || option === configJSON.allFiltersAr) {
      let { filters } = this.state;
      return (
        <>
          <MenuItem disableRipple>
            <Box sx={this.priceContainer()}>
              <Box className="filterRangeBlock">
                <label className="menuTitle">{this.getSpecifiedLangString(this.state.language, pageTxtData.revenueGross, configJSON.revenueGrossArabic)}</label>
                <Chart
                  width="100%"
                  chartType="ColumnChart"
                  height="80px"
                  data={this.state.priceGraph}
                />

                <RangeSlider
                  value={filters.ltm_revenue.values}
                  onChange={(event, newValue) =>
                    this.handleFilters(event, newValue, "ltm_revenue")
                  }
                  min={filters.ltm_revenue.ltmLimit[0]}
                  data-testid="ltm_revenue"
                  max={filters.ltm_revenue.ltmLimit[1]}
                />
                <Box className="rowBoxNRange">
                  <FormControl className="formInput">
                    <label>{this.getSpecifiedLangString(this.state.language, pageTxtData.minimumTxt, "الحد الأدنى")}</label>
                    <TextField
                      variant="outlined"
                      name="minimum"
                      type="number"
                      InputLabelProps={{ shrink: false }}
                      value={filters.ltm_revenue.values[0]}
                      data-testid="ltm_revenue_minimum"
                      onChange={(event) =>
                        this.handleFiltersEvent(
                          event,
                          "ltm_revenue",
                          "minimum"
                        )
                      }
                    />
                  </FormControl>
                  <Typography component="p" className="mark-list">
                    <span>-</span>
                  </Typography>
                  <FormControl className="formInput">
                  <label>{this.getSpecifiedLangString(this.state.language, pageTxtData.maximumTxt, "الحد الأقصى")}</label>
                    <TextField
                      variant="outlined"
                      name="maximum"
                      type="number"
                      value={filters.ltm_revenue.values[1]}
                      data-testid="ltm_revenue_maximum"
                      onChange={(event) =>
                        this.handleFiltersEvent(
                          event,
                          "ltm_revenue",
                          "maximum"
                        )
                      }
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box className="filterRangeBlock">
                <label className="menuTitle">{this.getSpecifiedLangString(this.state.language, pageTxtData.grossProfit, configJSON.grossProfitArabic)}</label>
                <Chart
                  width="100%"
                  chartType="ColumnChart"
                  height="80px"
                  data={this.state.priceGraph}
                />

                <RangeSlider
                  value={filters.ltm_profit.values}
                  onChange={(event, newValue) =>
                    this.handleFilters(event, newValue, "ltm_profit")
                  }
                  min={filters.ltm_profit.ltmProfitLimit[0]}
                  max={filters.ltm_profit.ltmProfitLimit[1]}
                  data-testid="ltm_profit"
                />
                <Box className="rowBoxNRange">
                  <FormControl className="formInput">
                  <label>{this.getSpecifiedLangString(this.state.language, pageTxtData.minimumTxt, "الحد الأدنى")}</label>
                    <TextField
                      variant="outlined"
                      name="minimum"
                      type="number"
                      InputLabelProps={{ shrink: false }}
                      value={filters.ltm_profit.values[0]}
                      data-testid="ltm_profit_minimum"
                      onChange={(event) =>
                        this.handleFiltersEvent(
                          event,
                          "ltm_profit",
                          "minimum"
                        )
                      }
                    />
                  </FormControl>
                  <Typography component="p" className="mark-list">
                    <span>-</span>
                  </Typography>
                  <FormControl className="formInput">
                  <label>{this.getSpecifiedLangString(this.state.language, pageTxtData.maximumTxt, "الحد الأقصى")}</label>
                    <TextField
                      variant="outlined"
                      name="maximum"
                      type="number"
                      data-testid="ltm_profit_maximum"
                      value={filters.ltm_profit.values[1]}
                      onChange={(event) =>
                        this.handleFiltersEvent(
                          event,
                          "ltm_profit",
                          "maximum"
                        )
                      }
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box className="filterRangeBlock">
                <label className="menuTitle">{this.getSpecifiedLangString(this.state.language, pageTxtData.locationTxt, "الموقع")}</label>
                <Box className="locationBlock">
                <Autocomplete
              id="country-select-demo"
              style={{ width: 300 }}
              options={ this.getSpecifiedLangString(this.state.language, webConfigJSON.countriesData,configJSON.arabicCountriesData)}
              autoHighlight
              data-test-id="filterRangeBlock"
              getOptionLabel={(option: CountryOption) => option.country.toString()}
              onChange={(event, value) => this.handleLocationChange("selectedCountry", value)}
              renderOption={(option: CountryOption) => (
                  <Box
                      component="li"
                      sx={{
                          display: 'flex',
                          alignItems: 'center',
                      }}
                  >
                      <img
                          width="20"
                          src={option.icon}
                          alt=""
                          style={{ marginRight: 8 }}
                      />
                      {option.country}
                  </Box>
              )}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="outlined"
                      placeholder={this.getSpecifiedLangString(this.state.language, "Country", "الدولة")}
                  />
              )}
          />
                </Box>
                <Box className="locationBlock">
                  <Autocomplete
                    id="country-select-demo"
                    style={{ width: 300 }}
                    options={filters.location.country?.cities || []}
                    autoHighlight
                    getOptionLabel={(option: any) => option.toString()}
                    onOpen={this.handleCityOpen}
                    data-testid="city"
                    value={filters.location.city}
                    onChange={(event, value) => this.handleLocationChange("selectedCity", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={this.getSpecifiedLangString(this.state.language,"City","مدينة")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>


              <Box className="filterRangeBlock">
                <label className="menuTitle">{this.getSpecifiedLangString(this.state.language, this.state.cataloguePageText.startupAgeText, configJSON.startupAgeTextArabic)}</label>
                <RangeSlider
                  value={filters.age.values}
                  valueLabelDisplay="auto"
                  onChange={(event, newValue) => this.handleFilters(event, newValue, "age")}                    
                  min={filters.age.startUpLimit[0]}
                  max={filters.age.startUpLimit[1]}
                  marks={filters.age.marks}
                />
              </Box>
           
            </Box>
          </MenuItem>
        </>
      );
    } else if(option === configJSON.price || option === configJSON.priceAr) {
      return (
        <>
          <MenuItem disableRipple>
            <Box sx={this.priceContainer()}>
              <label className="menuTitle">{this.getSpecifiedLangString(this.state.language, pageTxtData.askingPrice, "السعر المطلوب")}</label>
              <Chart
                width="100%"
                chartType="ColumnChart"
                height="80px"
                data={this.state.priceGraph}
              />

              <RangeSlider
                value={this.state.priceRangeValue}
                onChange={this.handlePriceRangeChange}
                min={this.state.priceLimit[0]}
                max={this.state.priceLimit[1]}
                data-testid="priceRange"
              />
              <Box className="rowBoxNRange">
                <FormControl className="formInput">
                <label>{this.getSpecifiedLangString(this.state.language, pageTxtData.minimumTxt, "الحد الأدنى")}</label>
                  <TextField
                    variant="outlined"
                    name="minimum"
                    type="number"
                    InputLabelProps={{ shrink: false }}
                    value={this.state.priceRangeValue[0]}
                    data-testid="priceRangeMin"
                    onChange={this.handlePriceRanges}
                  />
                </FormControl>
                <Typography component="p" className="mark-list">
                  <span>-</span>
                </Typography>
                <FormControl className="formInput">
                <label>{this.getSpecifiedLangString(this.state.language, pageTxtData.maximumTxt, "الحد الأقصى")}</label>
                  <TextField
                    variant="outlined"
                    name="maximum"
                    type="number"
                    value={this.state.priceRangeValue[1]}
                    data-testid="priceRangeMax"
                    onChange={this.handlePriceRanges}
                  />
                </FormControl>
              </Box>
            </Box>
          </MenuItem>
        </>
      );
    } else if(option === configJSON.sortBy || option === configJSON.sortByAr) {
      return (
        <>
          <MenuItem disableRipple>
            <Box sx={this.sortByOptionMenuStyle()}>
              <RadioGroup
                data-test="filter-sort-by"
                aria-label="sort-by"
                name="filter-sort-by"
                value={this.state.filterSortBy}
                onChange={this.handleFilterSortByMenu}
              >
                {this.state.sortByData.map((sortBy) => (
                  <React.Fragment key={sortBy.id}>
                    <FormControlLabel
                      value={sortBy.id}
                      control={<Radio />}
                      label={sortBy.name}
                    />
                  </React.Fragment>
                ))}
              </RadioGroup>
            </Box>
          </MenuItem>
        </>
      );
    }
  };

  listingData = () => {
    return (
      <Box className="mainHeaderWrapper">
              {this.state.loading ? (
                  <Box width={"100%"} height={"300px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                      <CircularProgress />
                  </Box>
              ) : (
              this.state.listingsCards.length === 0 && (
                <Box sx={this.filterMsgStyle()}>
                  <label className="no-records">{this.state.pageTxtData.noRecFoundTxt}</label>
                </Box>
              ))
            }
        
        {this.state.listings === "1" ? (
          <Box sx={this.listBox()}>
            {this.state.listingsCards.map((list, i) => (
              <CatalogueList
                data-test="bigCard"
                key={list.id}
                id={list.id}
                listyingType={this.state.listings}
                isBookmark={list.is_bookmarked}
                title={list.title}
                titleImgSrc={list.titleImgSrc}
                type={list.type as CatalogueType}
                annualProfit={list.annualProfit}
                annualRevenue={list.annualRevenue}
                basedIn={list.basedIn}
                description={list.description}
                employeesCount={list.employeesCount}
                pageText={this.state.cataloguePageText}
                foundedIn={list.foundedIn}
                originListing={list.originListing}
                proposedValuation={list.proposedValuation}
                peRatio={list.peRatio}
                token={this.state.token}
                refreshList={this.getCatalogueRequest}
                language={this.state.language==="en" ? "en" : "ar"}
                isDealClosed={list.isDealClosed}
                is_flag={list.is_flag}
                requestInitiated={list.requestInitiated}
              />
            ))}
          </Box>
        ) : (
          <>
            <Box sx={this.listingsCardsStyle()}>
              <Box className="inner-box">
                {this.state.listingsCards.map((list, i) => (
                  <CatalogueList
                    data-test="smallCard"
                    key={i}
                    listyingType={this.state.listings}
                    id={list.id}
                    isBookmark={list.is_bookmarked}
                    title={list.title}
                    titleImgSrc={list.titleImgSrc}
                    type={list.type as CatalogueType}
                    annualRevenue={list.annualRevenue}
                    annualProfit={list.annualProfit}
                    description={list.description}
                    basedIn={list.basedIn}
                    employeesCount={list.employeesCount}
                    originListing={list.originListing}
                    foundedIn={list.foundedIn}
                    proposedValuation={list.proposedValuation}
                    peRatio={list.peRatio}
                    token={this.state.token}
                    refreshList={this.getCatalogueRequest}
                    pageText={this.state.cataloguePageText}
                    language={this.state.language==="en" ? "en" : "ar"}
                    isDealClosed={list.isDealClosed}
                    requestInitiated={list.requestInitiated}
                    is_flag={list.is_flag}
                  />
                ))}
              </Box>
            </Box>
            <Box sx={this.listingsCardsMobileStyle()}>
              {this.state.catalogueListForThreeCard.map((list, i) => (
                <Slider {...this.settings} className="listing-slider" key={i}>
                  {list.map((card, j) => (
                    <CatalogueList
                      data-test="bigCard"
                      key={i}
                      listyingType={this.state.listings}
                      id={card.id}
                      isBookmark={card.is_bookmarked}
                      title={card.title}
                      titleImgSrc={card.titleImgSrc}
                      type={card.type as CatalogueType}
                      annualProfit={card.annualProfit}
                      annualRevenue={card.annualRevenue}
                      basedIn={card.basedIn}
                      description={card.description}
                      employeesCount={card.employeesCount}
                      foundedIn={card.foundedIn}
                      originListing={card.originListing}
                      proposedValuation={card.proposedValuation}
                      peRatio={card.peRatio}
                      token={this.state.token}
                      language={this.state.language==="en" ? "en" : "ar"}
                      refreshList={this.getCatalogueRequest}
                      pageText={this.state.cataloguePageText}
                      isDealClosed={card.isDealClosed}
                      requestInitiated={card.requestInitiated}
                      is_flag={card.is_flag}
                    />
                  ))}
                </Slider>
              ))}
            </Box>
          </>
        )}
        </Box>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainDiv}>
        <HeaderBlock />
        <Box className="checking21" sx={this.filterMenuStyle()}>
          <Box className="filter-menu-list">
            <Box className="filter-menu-box">
            {this.state.filterSelectionType.map((selection: any) => (
                <Box
                  data-test="box-component"
                  className="filter-menu"
                  key={selection.id}
                  onClick={(e) => this.handleFilterMenuClick(e, selection.id)}
                >
                  <Typography className="menu-text">{selection.label}</Typography>
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.4516 9.27487L15.0891 2.30862C15.1999 2.19239 15.2617 2.03796 15.2617 1.87737C15.2617 1.71679 15.1999 1.56236 15.0891 1.44613L15.0816 1.43862C15.0279 1.38208 14.9632 1.33706 14.8916 1.30629C14.8199 1.27552 14.7427 1.25966 14.6647 1.25966C14.5867 1.25966 14.5096 1.27552 14.4379 1.30629C14.3662 1.33706 14.3016 1.38208 14.2478 1.43862L7.99785 7.99862L1.75035 1.43862C1.69663 1.38208 1.63198 1.33706 1.56031 1.30629C1.48864 1.27552 1.41147 1.25966 1.33348 1.25966C1.25548 1.25966 1.17831 1.27552 1.10664 1.30629C1.03497 1.33706 0.970316 1.38208 0.916599 1.43862L0.9091 1.44613C0.798293 1.56236 0.736479 1.71679 0.736479 1.87737C0.736479 2.03796 0.798293 2.19239 0.9091 2.30862L7.5466 9.27487C7.60497 9.33614 7.67518 9.38491 7.75296 9.41824C7.83074 9.45157 7.91448 9.46875 7.9991 9.46875C8.08372 9.46875 8.16746 9.45157 8.24524 9.41824C8.32302 9.38491 8.39323 9.33614 8.4516 9.27487Z"
                      fill="black"
                      stroke="black"
                    />
                  </svg>
                </Box>
              ))}
              {(
                this.state.filterType.length > 0 ||
                (this.state.priceRangeValue[0] !== this.state.priceLimit[0] || this.state.priceRangeValue[1] !== this.state.priceLimit[1]) ||
                this.state.listings !== "1" ||
                this.state.filterSortBy !== 'price_low_to_high' || 
                !(this.state.filters.revenue_multiple.values.includes(3000) && this.state.filters.revenue_multiple.values.includes(40000)) ||
                !(this.state.filters.ltm_revenue.values.includes(3000) && this.state.filters.ltm_revenue.values.includes(40000)) ||
                !(this.state.filters.ltm_profit.values.includes(3000) && this.state.filters.ltm_profit.values.includes(40000)) ||
                (this.state.filters.location.country !== "" && this.state.filters.location.cities !== "") ||
                !(this.state.filters.annual_revenue.values.includes(3000) && this.state.filters.annual_revenue.values.includes(40000)) ||
                !(this.state.filters.growth_rate.values.includes(3000) && this.state.filters.growth_rate.values.includes(40000)) ||
                !(this.state.filters.age.values.includes(0) && this.state.filters.age.values.includes(100)) ||
                this.state.filterRange.some((item: any) => item.isChecked)
                 
              ) && (

                  <Box
                    data-testid="clear-box-component"
                    className="clear-filter-menu"
                    onClick={this.clearAllFilters}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3002 5.71022C18.2077 5.61752 18.0978 5.54397 17.9768 5.49379C17.8559 5.44361 17.7262 5.41778 17.5952 5.41778C17.4643 5.41778 17.3346 5.44361 17.2136 5.49379C17.0926 5.54397 16.9827 5.61752 16.8902 5.71022L12.0002 10.5902L7.11022 5.70022C7.01764 5.60764 6.90773 5.5342 6.78677 5.4841C6.6658 5.43399 6.53615 5.4082 6.40522 5.4082C6.27429 5.4082 6.14464 5.43399 6.02368 5.4841C5.90272 5.5342 5.79281 5.60764 5.70022 5.70022C5.60764 5.79281 5.5342 5.90272 5.4841 6.02368C5.43399 6.14464 5.4082 6.27429 5.4082 6.40522C5.4082 6.53615 5.43399 6.6658 5.4841 6.78677C5.5342 6.90773 5.60764 7.01764 5.70022 7.11022L10.5902 12.0002L5.70022 16.8902C5.60764 16.9828 5.5342 17.0927 5.4841 17.2137C5.43399 17.3346 5.4082 17.4643 5.4082 17.5952C5.4082 17.7262 5.43399 17.8558 5.4841 17.9768C5.5342 18.0977 5.60764 18.2076 5.70022 18.3002C5.79281 18.3928 5.90272 18.4662 6.02368 18.5163C6.14464 18.5665 6.27429 18.5922 6.40522 18.5922C6.53615 18.5922 6.6658 18.5665 6.78677 18.5163C6.90773 18.4662 7.01764 18.3928 7.11022 18.3002L12.0002 13.4102L16.8902 18.3002C16.9828 18.3928 17.0927 18.4662 17.2137 18.5163C17.3346 18.5665 17.4643 18.5922 17.5952 18.5922C17.7262 18.5922 17.8558 18.5665 17.9768 18.5163C18.0977 18.4662 18.2076 18.3928 18.3002 18.3002C18.3928 18.2076 18.4662 18.0977 18.5163 17.9768C18.5665 17.8558 18.5922 17.7262 18.5922 17.5952C18.5922 17.4643 18.5665 17.3346 18.5163 17.2137C18.4662 17.0927 18.3928 16.9828 18.3002 16.8902L13.4102 12.0002L18.3002 7.11022C18.6802 6.73022 18.6802 6.09022 18.3002 5.71022Z"
                        fill="#FF7575"
                      />
                    </svg>

                    <Typography className="menu-text">{this.getSpecifiedLangString(this.state.language, "Clear", "مسح")}</Typography>
                  </Box>
                )}
            </Box>
          </Box>
          <Box className="search-box">
            <Box className="searchTextBox">
            <input
              data-testid="searchKey"
              placeholder={this.getSpecifiedLangString(this.state.language,this.state.pageTxtData.searchTxt,"بحث")}
              onChange={this.handleChangeSearch}
              className="text-box"
            />
            </Box>
            <Box className="search-icon-box">
              <svg
                className="icon"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 21L15 15M3 10C3 10.9193 3.18106 11.8295 3.53284 12.6788C3.88463 13.5281 4.40024 14.2997 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.1705 16.8189 9.08075 17 10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.1705 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C9.08075 3 8.1705 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C4.40024 5.70026 3.88463 6.47194 3.53284 7.32122C3.18106 8.1705 3 9.08075 3 10Z"
                  stroke="#10BFB8"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Box>
          </Box>
        </Box>

        {this.listingData()}

        <StyledMenu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={!!this.state.select}
          onClose={this.clearFilterMenu}
          style={{ background: "rgba(0,0,0,0.5)", padding: "0px !important" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          classes={{
            paper: configJSON.type === this.state.select ? "selected" : "",
          }}
        >
          {this.getFilterMenuOption(this.state.select)}
        </StyledMenu>
        <FooterBlock />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export enum CatalogueType {
  BUSINESS = "sme",
  STARTUP = "start up",
}

const StyledMenu = withStyles({
  paper: {
    borderRadius: "20px",
    padding: "0 !important",
    marginTop: "35px",
    boxShadow: "none",
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "45px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9", // Color of the scrollbar thumb
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "darkgrey", // Color of the scrollbar thumb on hover
    },
    "&.selected": {
      background: "transparent",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
      "& .MuiMenuItem-root": {
        alignItems: "flex-start",
      },
    },
    "@media (max-width:759px)": {
      "&.selected": {
        borderBottomLeftRadius: "0",
      },
    },
    "@media (max-width:600px)": {
      marginTop: "16px",
    },
  },
  list: {
    padding: "0 !important",
    "& .MuiMenuItem-root": {
      cursor: "context-menu",
      padding: "0 !important",
      "& label": {
        cursor: "pointer",
        "& *": {
          cursor: "pointer",
        },
      },
    },
    "@media (max-width:759px)": {
      "& .MuiMenuItem-root": {
        display: "grid",
        justifyItems: "start",
        maxHeight: "auto",
      },
    },
  },
})(Menu);

const webStyle = {
  mainDiv: {
    width: "100%",
    // height: "1457.489px",
    height: "1200px",
    [theme.breakpoints.down("sm")]: {
      height: "600px",
    },
    position: "relative",
  },
  navbar: {
    display: "flex", justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    backgroundColor: "pink",
  },
  productContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "100%",
    marginTop: "20px",
    backgroundColor: "white",
  },
  productBox: {
    marginRight: "5px",
    flexDirection: "column",
    height: 250,
    width: "49%",
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  productImg: {
    height: "100%",
    width: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
