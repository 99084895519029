export const onBoardingOne = require("../assets/Onboarding_One.png");
export const onBoardingTwo = require("../assets/Onboarding_Two.png");
export const onboardingThree = require("../assets/Onboarding_Three.png");
export const onboardingFour = require("../assets/Onboarding_Four.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mainTutorialBg = require("../assets/MainTutorialBg.svg");
export const mainTutorialBgMobile = require("../assets/MainTutorialBgMobile.svg");
export const namiLogo = require("../assets/NamiLogo.svg");
export const tutorialArrowBg = require("../assets/arrow.svg");
export const forwardArrow=require("../assets/forwardArrow.svg");