import { Route, Redirect, withRouter } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";



const isAuthenticated = (roles) => {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('typeOfUser');

  if (token === null) {
    return false;
  }

  if (roles && roles.length > 0) {
    return roles.includes(userRole);
  }

  return true;
};

const ProtectedRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated(roles) ? (
        <Component {...props} />
      ) : (() => {
        localStorage.removeItem("token")
        return <Redirect to="/EmailAccountLoginBlock" />
      })()
    }
  />
);

const AuthRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated(roles) ? (
        localStorage.getItem('typeOfUser') == 'buyer' ? <Redirect to="/Catalogue" /> : <Redirect to="/MyAcquisition" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

function Wrapper({ element, history, match, routeMap, closeModal, activeRoute }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      params ? history.push(url, params) : history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack, history },
    closeModal,
    activeRoute
  });
}

const WrappedWrapper = withRouter(Wrapper);

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object,
  activeRoute: PropTypes.string
};

const WebRoutesGenerator = ({ routeMap }) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      switch (currentRoute.routeType) {
        case 'Protected':
          return (
            <ProtectedRoute
              key={currentRoute.path}
              path={currentRoute.path}
              roles={currentRoute.roles}
              exact={currentRoute.exact}
              component={props => (
                <WrappedWrapper activeRoute={currentRoute.path} element={<Component />} {...props} routeMap={routeMap} />
              )}
            />
          );
        case 'Auth':
          return (
            <AuthRoute
              key={currentRoute.path}
              path={currentRoute.path}
              roles={currentRoute.roles}
              exact={currentRoute.exact}
              component={props => (
                <WrappedWrapper activeRoute={currentRoute.path} element={<Component />} {...props} routeMap={routeMap} />
              )}
            />
          );
        default:
          return (
            <Route
              key={currentRoute.path}
              path={currentRoute.path}
              exact={currentRoute.exact}
              render={props => (
                <Wrapper element={<Component />} {...props} routeMap={routeMap} />
              )}
            />
          );
      }

    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
