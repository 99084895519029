// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/styles";

import FeedbackController, { Props, configJSON } from "./FeedbackController.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { Box, Button, FormControl, Input } from "@material-ui/core";
import { EmptyStarIcon, FullStarIcon} from "./assets";
// Customizable Area End

export class AddFeedback extends FeedbackController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderBlock />
        <div className={classes.mainComponent} data-test-id="main-feedback">
          
        </div>
        <Box className={classes.accessRequestBox} style={{paddingBottom: "4rem"}}>
            <Box className={`${classes.subscriptionBox} subscriptionBox`}>
            <FormControl fullWidth>
                <div style={{textAlign: 'center'}}>
              
                <label className="label-title">{this.state.lauguageSelect==="en" ? "Let us know how we did !":"اسمحوا لنا أن نعرف كيف فعلنا !"}</label>
                </div>
                
                <div className={'desc'}>{this.state.lauguageSelect==="en" ?"Feedback" :"تعليق"}<span style={{color: "#FF7575"}}>*</span></div>
                <div style={{marginBottom: "3rem"}}>
                
              <Input
                placeholder={this.state.lauguageSelect==="en" ?"Feedback" :"تعليق"}
                data-test-id="feedback-input"
                className={classes.feedbackDialogInput}
                multiline
                rows={8}
                fullWidth
                value={this.state.feedbackInputValue}
                onChange={this.handleFeedbackInputChange}
                required={true}
                error={this.state.feedbackError}
                
              />
                {this.state.feedbackError && <div style={{ color: 'red' }}>{this.state.lauguageSelect==="en" ? "Feedback is required ":"ردود الفعل مطلوبة"}</div>}
              <div className={'desc'}>{this.state.lauguageSelect==="en" ? "Rating" :"تقييم"}<span style={{ color: "#FF7575" }}>*</span></div>
              <div style={{ textAlign: 'center' }}>
              {[...Array(5)].map((_value, valIndex) => (
                <span key={valIndex}>
                  <img data-test-id={`index-${valIndex}`} style={{cursor: 'pointer', paddingRight: '12px'}} onClick={() => this.handleStarClick(valIndex + 1)}
                   src={valIndex < this.state.rating ? FullStarIcon : EmptyStarIcon} />
                </span>
              ))}
              </div>
              {this.state.ratingError && <div style={{ color: 'red' }}>{this.state.lauguageSelect==="en" ?"Rating is required":"التقييم مطلوب"}</div>}
            </div><div className="btnGroup" style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button data-test-id="feedback-clear-button" onClick={() => this.handleFeedbackInputClear()} className="metaBtn grey">{this.getContent(configJSON.clearText,configJSON.clearArabicText)}</Button>
                <Button data-test-id="success-btn" onClick={() => this.handleSubmit()}  className="metaBtn purp">{this.getContent(configJSON.submitText,configJSON.submitArabicText)}</Button>
                </div>
                </FormControl>
            </Box>
            </Box>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  mainComponent: {
    padding: "88px 0px",
    display: "flex",
    height: "835px",
    "& .MuiDivider-root": {
      backgroundColor: "#8C8C8C",
    },
    "@media (max-width: 758px)": {
      padding: "2rem 0"
    },
  },
  
  accessRequestBox:{
    display: 'flex',
    justifyContent: 'center', 
    position: "relative",
    borderBottom: "1px solid #8c8c8c",
    "& img":{
        zIndex: "0",
        maxWidth: "100%"
    }
},
subscriptionBox:{
  "borderRadius": "20px",
  "border": "2px solid #5412FC",
  "background": "#FFF",
  minWidth: "600px",
  padding: "40px",
  position: "relative",
 
  "@media (max-width: 758px)": {
    padding: "1rem 2rem",
    minWidth: "auto",
    width: "100%",
    margin: "1rem"
  },
  "& .row":{
      gap: "83px",
      paddingLeft: "53px",
      alignItems: "normal",
      "& svg path":{
          stroke: "#5412FC",
          fill: "#5412FC",
      }
  },
  "& .metaBtn":{
      "borderRadius": "12px",
      "opacity": "0.5",
      "width": "200px",
      "height": "60px",
      "color": "#FFF",
      "fontFamily": "Montserrat",
      "fontSize": "20px",
      "fontStyle": "normal",
      "fontWeight": "700",
      "lineHeight": "24px",
      textTransform: "none",
      transition: "0.3s",
      "& span":{
          display: "flex",
          gap: "20px"
      },
      "&:hover":{
          opacity: "1"
      }
  },
  "& .purp": {
      "background": "#5412FC",
  },

  "& .grey": {
      "background": "grey",
  },
  "& .label-title":{
 
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '34px',
  color: "#5412FC"},
  "& .span-sub":{
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '34px',
      display: 'flex',
      alignItems: 'center',
      color: '#000000',
      marginTop: '60px'
  },
  "& .desc": {
      fontFamily: 'Montserrat',
fontStyle: 'normal',
fontWeight: 500,
fontSize: '18px',
lineHeight: '24px',
display: 'flex',
alignItems: 'center',
color: '#000000',
marginTop: '60px',
marginBottom: '12px',

"@media (max-width: 758px)": {
  marginTop: '2rem',
},
  },
  "& .title":{
      "color": "#262626",
      "fontFamily": "Montserrat",
      "fontSize": "32px",
      "fontStyle": "normal",
      "fontWeight": 700,
      "lineHeight": "36px"
  },
  "& .btnGroup": {
    "@media (max-width: 532px)": {
      flexDirection: "column",
      gap: "12px",
      "& button": {
        width: "100%"
      }
    },
  }
},
  feedbackDialogInput: {
    width: "100%",
    borderRadius: "12px",
    border: "1px #8C8C8C solid",
    padding: "24px",
    margin: "0",
    "&.MuiInput-underline:before": {
      display: "none",
    },
    "&.MuiInput-underline:after": {
      display: "none",
    },
    "@media (max-width: 758px)": {
      padding: "1rem",
    },
  },
};

export default withStyles(styles)(AddFeedback);
// Customizable Area End
