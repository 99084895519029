import React from "react";
import CatalogueListController , {configJSON} from "./CatalogueListController.web";
// Customizable Area Start
import {Link} from "react-router-dom"
import {
  Box,
  Grid,
  Typography,
  Button,
  createStyles,
  withStyles,
} from "@material-ui/core";
import {
  revenueLogo,
  usersIcon,
  profitLogo,
  calendarLogo,
  mapLogo,
  flagHover,
  shareHover,
  valuationLogo,
  bookMarkHover,
  bookMark,
  flag,
  share,
  info,
  bookMarked,
  flaged,
} from "./assets";
import {
  CatalogueFlagBox,
  CatalogueShareBox,
  BookmarkPopup,
} from "./CatalogueFeature.web";
import { conditionSort, formatNumber } from "../../../components/src/Helper";
interface IPageText  {
  shareBoxTitle:string;
  flagBoxDetails:string;
  faceBookText:string;
  whatsAppText:string;
  viaLinkText:string;
  instragramText:string;
  TwitterText:string;
  LinkedInText:string;
  addListTxt:string;
  listTitleTextField:string;
  bookmarkModalTitle:string;
  chooseThisWillText:string;
  flagTheListing:string;
  reportBlogTitle:string;
  yesText:string;
  flagBoxTitle:string;
  archiveListingText:string;
  reportText:string;
  noText:string;
  ArchiveText:string;
  hideTheListingText:string;
}
// Customizable Area End

export class CatalogueList extends CatalogueListController {
  getGridRecords = () => {
    const {
      title,
      titleImgSrc,
      annualProfit,
      annualRevenue,
      basedIn,
      description,
      employeesCount,
      foundedIn,
      proposedValuation,
      classes,
      pageText,
      isDealClosed,
    } = this.props;
    return this.state.flagHandle ? (
      <CatalogueFlagBox
        language = {this.props.language}
        title={title}
        catalogType="grid"
        catalogueIcon={titleImgSrc}
        classes={classes}
        reportHandle={this.catalogueReport}
        flagReportModalClose={this.handleFlagCloseModal}
        flagReportModal={this.handleFlagModal}
        flagReportModalState={this.state.flagModalStatus}
        flaArchiveModalClose={this.flagArchiveModal}
        flagArchiveModalHandle={this.flagArchiveModal}
        archiveHandle={this.archiveHandle}
        flagArchiveModalState={this.state.flagArchiveModalState}
        handleFlage={this.handleFlage}
        flagReportList={this.state.flagReportList}
        pageText={this.getContent(pageText,
          {
            ...pageText,
            flagBoxTitle: configJSON.flagBoxTitleArabic,
            reportText: configJSON.reportTextArabic,
            chooseThisWillText: configJSON.chooseThisWillTextArabic,
            hideTheListingText: configJSON.hideTheListingTextArabic,
            reportBlogTitle: configJSON.reportBlogTitleArabic,
            archiveListingText: configJSON.archiveListingTextArabic,
            flagTheListing: configJSON.flagTheListingArabic,
            ArchiveText: configJSON.ArchiveTextArabic,
            yesText: configJSON.yesTextArabic,
            noText: configJSON.noTextArabic,
            flagBoxDetails: configJSON.flagBoxDetailsArabic
          }) as IPageText}
      />
    ) : (
      [
        this.state.shareHandler ? (
          <CatalogueShareBox
            title={title}
            catalogueIcon={titleImgSrc}
            catalogType="grid"
            classes={classes}
            flagReportModalState={this.state.flagModalStatus}
            handleShareLink={this.handleShareLink}
            handleShare={this.handleShare}
            language={this.props.language}
            pageText={this.getContent(
              { ...pageText, flagBoxDetails: configJSON.shareListingDetails },
              {
                ...pageText, shareBoxTitle: configJSON.shateBoxTitleArabic,
                flagBoxDetails: configJSON.shareListingDetailsArabic,
                TwitterText: configJSON.TwitterTextArabic,
                instragramText: configJSON.instagramTextArabic,
                whatsAppText: configJSON.whatsAppTextArabic,
                faceBookText: configJSON.faceBookTextArabic,
              }) as IPageText}
          />
        ) : (
          <Box className={isDealClosed ? `${classes.disabledCardContainer} ${classes.smallCardContainer}` : classes.smallCardContainer}>
              {!isDealClosed ? <Link to={`/CompanyProfile/${this.props.id}`} style={{textDecoration: "none",  color: "inherit"}}>
                  {this.renderDealClosedBoxForGrid(isDealClosed)}
              </Link> : this.renderDealClosedBoxForGrid(isDealClosed)}
            {this.cardFooter()}
          </Box>
        ),
      ]
    );
  };

  isArabicListing = () => {
    return (
      <Box className={this.props.isDealClosed ? "closedOriginalListing original-listing" : "original-listing"}>
        <img src={info} />
          <Typography className="listing-text">
            {this.props.pageText.originalListingText + " " + "Arabic"}</Typography>
        </Box>
    )
  }

  isEnglishListing = () => {
    return (
      <Box className={this.props.isDealClosed ? "closedOriginalListing original-listing" : "original-listing"}>
        <img src={info} />
          <Typography className="listing-text">
            {`القائمة الأصلية باللغة الإنجليزية`}
          </Typography>
        </Box>
    )
  }

  originlisting = () => {
    return (
      <>
      {this.props.language == "en" && this.props.originListing?.toLowerCase() == "arabic" && this.isArabicListing()}
      {this.props.language === "ar" && this.props.originListing?.toLowerCase() == "english" && this.isEnglishListing()}
        </>
    )
  }

  cardFooter = () => {
    const { classes, isBookmark, listyingType, pageText, isDealClosed,is_flag } = this.props;
    
    return (
      <Box
        className={
          classes.cardFooterStyle +
          " " +
          `${listyingType !== "1" ? classes.smallCardFooter : ""}`
        }
      >
        {isBookmark ? (
          <Button
            onClick={this.UnBookmarkCatalogue}
            data-testid="UnBookmarkIcon"
            data-tooltip={this.getContent(configJSON.unbookmark,configJSON.arabicUnbookmark)}
            className={this.handleDisable() ? "closedDealBtns" : "normalBtn"}
            disabled={this.handleDisable()}
          >
            <img src={bookMarked} />
          </Button>
        ) : (
          <Button
            data-tooltip={this.getContent(pageText.bookmarkText,configJSON.bookmarkTextArabic)}
            onClick={this.handleBookmarkModal}
            data-testid="bookmarkIcon"
              className={this.handleDisable() ? "closedDealBtns bookmarkIcon" : "bookmarkIcon"}
              disabled={this.handleDisable()}
          >
            <img src={bookMark} />
          </Button>
        )}
        <Button
          data-tooltip={this.langConditionRendering(pageText.archiveReportText, "ارشفه / إبلاغ")}
          onClick={this.handleFlage}
          data-testid="flag-Icon"
          className={this.handleDisable() ? "closedDealBtns flagIcon" : "flagIcon"}
          disabled={this.handleDisable()}
        >
           <img
        data-testid="toggleImage"
        src={is_flag ? flaged : flag}
        alt="report"
      />
        </Button>
        <Button
          data-tooltip={this.langConditionRendering(pageText.shareListText,configJSON.shareListTextArabic)}
          onClick={this.handleShare}
          data-testid="shareIcon"
          className={isDealClosed ? "closedDealBtns shareIcon" : "shareIcon"}
          disabled = {isDealClosed}
        >
          <img src={share} />
        </Button>
        {this.originlisting()}

      </Box>
    );
  };

  cardHeader = () => {
    const { title, titleImgSrc, type, pageText, isDealClosed, requestInitiated } = this.props;
    const { typeImg, typeColor } = this.getTypeInfo(type);
    return (
      <>
        <Box className={isDealClosed ? "closedLeftSide left-side" : "left-side"}>
          <img style={{ backgroundColor: titleImgSrc ? 'none': typeColor, borderRadius: "50%" }} src={titleImgSrc || typeImg} />
          <Typography data-testid="title" className="title">{title}</Typography>
        </Box>
        <Box style={{display: "flex", gap: "4px"}}>
          {isDealClosed && 
          <Box className="dealClosedBox">
            <Typography className="dealClosedText" align="center">{
              this.getContent(pageText.dealClosedText,configJSON.dealClosedTextArabic)
            }</Typography>
          </Box>
          }
          <Box
            className={isDealClosed ? "closedRightSide right-side" : "right-side"}
            style={{ border: `2px solid ${typeColor}` }}
          >
            <Box className="img-box" style={{ backgroundColor: typeColor }}>
              <img src={typeImg} alt="" />
            </Box>
            <Typography className="type" style={{ color: typeColor }}>
              {(type?.toLowerCase() === "sme" || type === "س  مه") ? this.getContent(pageText.businessText,configJSON.businessTextArabicNew) :  this.langConditionRendering(pageText.startUpText,configJSON.startUpTextArabic)}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  renderDealClosedBox = () => {
    const {
      annualProfit,
      annualRevenue,
      basedIn,
      description,
      employeesCount,
      foundedIn,
      proposedValuation,
      peRatio,
      classes,
      pageText
    } = this.props;

    return (
      <Box className= "closedCardBox" >
      <>
        <Box
          className= {classes.cardTitleStyle} >
          {this.cardHeader()}
        </Box>
        <Box
          className="closedText contain-box" >
          <Box
            className="description">
            <Typography
              className="text"
            >
              {this.trimString(description, 120)}
            </Typography>
          </Box>
          <Grid container item xs={12} className="details" >
            <Grid item container lg={4} md={6} sm={12} xs={6} className="detail" >
            <Box style={{display:"flex"}}>
              <Box
                className="detail-logo">
                <img src={revenueLogo} />
              </Box>
              <Box
                className="detail-text"
              >
                <Box
                  className="text-heading">
                  <Typography
                    className="heading">
                      {this.getContent(pageText.AnnualRevenueText,configJSON.AnnualRevenueTextArabic)}
                  </Typography>
                </Box>
                <Box
                  className="text-value"
                >
                  <Typography
                    className="value"
                  >
                    SAR {formatNumber(annualRevenue)}
                  </Typography>
                </Box>
              </Box>
              </Box>
            </Grid>
            <Grid item container lg={4} md={6} sm={12} xs={6} className="detail" >
              <Box
                className="detail-logo">
                <img
                  src={profitLogo}
                />
              </Box>
              <Box
                className="detail-text">
                <Box
                  className="text-heading">
                  <Typography
                    className="heading">
                      {this.getContent(pageText.annualProfitText,configJSON.annualProfitTextArabic)}
                  </Typography>
                </Box>
                <Box
                  className="text-value">
                  <Typography
                    className="value"
                  >
                    SAR {formatNumber(annualProfit)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item container lg={4} md={6} sm={12} xs={6} className="detail"  >
              <Box
                className="detail-logo">
                <img
                  src={mapLogo}
                />
              </Box>
              <Box
                className="detail-text">
                <Box
                  className="text-heading">
                  <Typography
                    className="heading">
                    {this.langConditionRendering(pageText.basedInText, configJSON.basedInTextArabic)}
                  </Typography>
                </Box>
                <Box
                  className="text-value">
                  <Typography
                    className="value">
                    {basedIn}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item container lg={4} md={6} sm={12} xs={6} className="detail" >
              <Box
                className="detail-logo">
                <img
                  src={usersIcon}
                />
              </Box>
              <Box
                className="detail-text" >
                <Box
                  className="text-heading">
                  <Typography
                    className="heading">
                      {this.getContent(pageText.numberOfEmployeesText, configJSON.numberOfEmployeesTextArabic)}
                  </Typography>
                </Box>
                <Box
                  className="text-value" >
                  <Typography
                    className="value" >
                    {employeesCount}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item container lg={4} md={6} sm={12} xs={6} className="detail" >
              <Box
                className="detail-logo">
                <img
                  src={calendarLogo}
                />
              </Box>
              <Box
                className="detail-text">
                <Box
                  className="text-heading">
                  <Typography
                    className="heading">
                      {this.getContent(pageText.foundedInText,configJSON.foundedInTextArabic)}
                  </Typography>
                </Box>
                <Box
                  className="text-value">
                  <Typography
                    className="value"
                  >
                    {foundedIn}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {conditionSort(this.props.type?.toLowerCase() === "sme" || this.props.type === "س  مه",<Grid item container lg={4} md={6} sm={12} xs={6} className="detail" >
              <Box
                className="detail-logo">
                <img src={valuationLogo} />
              </Box>
              <Box
                className="detail-text">
                <Box
                  className="text-heading">
                  <Typography className="heading">
                    {this.getContent(pageText.proposedEvalutionText,configJSON.proposedEvalutionTextArabic)}
                  </Typography>
                </Box>
                <Box
                  className="text-value" >
                  <Typography
                    className="value" >
                    {formatNumber(proposedValuation)}{" "}
                    <span>
                    {!!peRatio ? `(${peRatio}x P/E)` : ""}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>, null)}
          </Grid>
        </Box>
      </>
    </Box>
    )
            
  };

  renderDealClosedBoxForGrid = (isDealClosed: boolean) => {
    const {
      annualProfit,
      annualRevenue,
      basedIn,
      description,
      employeesCount,
      foundedIn,
      proposedValuation,
      peRatio,
      classes
    } = this.props;
    return (
      <>
              <Box className={classes.smallCardStyle}>{this.cardHeader()}</Box>
                  <Box className={isDealClosed ? "closedText contain-box" : "contain-box"}>
                    <Box className="description small-container-description">
                      <Typography className="text">
                        {this.trimString(description, 60)}
                      </Typography>
                    </Box>
                    <Grid container item xs={12} className="details small-container">
                      <Grid item container xs={4} className="detail">
                        <Box className="detail-logo">
                          <img src={revenueLogo} />
                        </Box>
                        <Box className="detail-text">
                          <Typography className="value">SAR {formatNumber(annualRevenue)}</Typography>
                        </Box>
                      </Grid>
                      <Grid item container xs={4} className="detail">
                        <Box className="detail-logo">
                          <img src={profitLogo} />
                        </Box>
                        <Box className="detail-text">
                          <Typography className="value">SAR {formatNumber(annualProfit)}</Typography>
                        </Box>
                      </Grid>
                      <Grid item container xs={4} className="detail">
                        <Box className="detail-logo">
                          <img src={mapLogo} />
                        </Box>
                        <Box className="detail-text">
                          <Typography className="value">{basedIn}</Typography>
                        </Box>
                      </Grid>
                      <Grid item container xs={4} className="detail">
                        <Box className="detail-logo">
                          <img src={usersIcon} />
                        </Box>
                        <Box className="detail-text">
                          <Typography className="value">{employeesCount}</Typography>
                        </Box>
                      </Grid>
                      <Grid item container xs={4} className="detail">
                        <Box className="detail-logo">
                          <img src={calendarLogo} />
                        </Box>
                        <Box className="detail-text">
                          <Typography className="value">{foundedIn}</Typography>
                        </Box>
                      </Grid>
                      <Grid item container xs={4} className="detail">
                        <Box className="detail-logo">
                          <img src={valuationLogo} />
                        </Box>
                        <Box className="detail-text">
                          <Typography className="value">
                          SAR {formatNumber(proposedValuation)}
                            <br />
                            <span>
                              {!!peRatio ? `(${peRatio}x P/E)` : ""}
                            </span>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
      </>
    )
            
  };

  renderValuation = () => {
    return (
      <Box className="text-value">
        <Typography className="value">
          {formatNumber(this.props.proposedValuation)}{" "}
          <span>
          {!!this.props.peRatio ? `(${this.props.peRatio}x P/E)` : ""}
          </span>
        </Typography>
      </Box>
    )
  };
  receiveCatalogueAPI: any;

  render() {
    const {
      title,
      titleImgSrc,
      listyingType,
      annualProfit,
      annualRevenue,
      basedIn,
      description,
      employeesCount,
      foundedIn,
      classes,
      language,
      pageText,
      isDealClosed
    } = this.props;

    return (
      <>
        {listyingType === "1" ? (
          <>
            {this.state.flagHandle ? (
              <CatalogueFlagBox
                language = {language}
                data-testid="flagBox"
                title={title}
                catalogueIcon={titleImgSrc}
                classes={classes}
                catalogType="list"
                pageText={this.getContent(pageText,
                  {
                    ...pageText,
                    flagBoxTitle: configJSON.flagBoxTitleArabic,
                    reportText: configJSON.reportTextArabic,
                    chooseThisWillText: configJSON.chooseThisWillTextArabic,
                    hideTheListingText: configJSON.hideTheListingTextArabic,
                    reportBlogTitle: configJSON.reportBlogTitleArabic,
                    archiveListingText: configJSON.archiveListingTextArabic,
                    flagTheListing: configJSON.flagTheListingArabic,
                    ArchiveText: configJSON.ArchiveTextArabic,
                    yesText: configJSON.yesTextArabic,
                    noText: configJSON.noTextArabic,
                    flagBoxDetails: configJSON.flagBoxDetailsArabic
                  }) as IPageText}
                reportHandle={this.catalogueReport}
                flagReportModalClose={this.handleFlagCloseModal}
                flagReportModal={this.handleFlagModal}
                flagReportModalState={this.state.flagModalStatus}
                flaArchiveModalClose={this.flagArchiveModal}
                flagArchiveModalHandle={this.flagArchiveModal}
                archiveHandle={this.archiveHandle}
                flagArchiveModalState={this.state.flagArchiveModalState}
                handleFlage={this.handleFlage}
                flagReportList={this.state.flagReportList}
                
              />
            ) : (
              [
                this.state.shareHandler ? (
                  <CatalogueShareBox
                    title={title}
                    catalogType="list"
                    catalogueIcon={titleImgSrc}
                    classes={classes}
                    flagReportModalState={this.state.flagModalStatus}
                    handleShareLink={this.handleShareLink}
                    handleShare={this.handleShare}
                    language={language}
                    pageText={this.getContent(
                      { ...pageText, flagBoxDetails: configJSON.shareListingDetails },
                      {
                        ...pageText, shareBoxTitle: configJSON.shateBoxTitleArabic,
                        flagBoxDetails: configJSON.shareListingDetailsArabic,
                        TwitterText: configJSON.TwitterTextArabic,
                        instragramText: configJSON.instagramTextArabic,
                        whatsAppText: configJSON.whatsAppTextArabic,
                        faceBookText: configJSON.faceBookTextArabic,
                      }) as IPageText}
                  />
                ) : (
                  <>
                      <Box className={isDealClosed ? `${classes.disabledCardContainer} ${classes.cardContainer}` : classes.cardContainer}>
                        {!isDealClosed ?
                      <Link to={`/CompanyProfile/${this.props.id}`} style={{textDecoration: "none",  color: "inherit"}}>
                      <><Box className={classes.cardTitleStyle}>
                        {this.cardHeader()}
                      </Box>

                              <Box className="contain-box" >
                        <Box className="description">
                          <Typography className="text">
                            {this.trimString(description, 120)}
                          </Typography>
                        </Box>
                                <Grid container className="details">
                          <Grid
                            item
                            container
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={6}
                            className="detail"
                          >
                            <Box style={{display:"flex"}}>

                            <Box className="detail-logo">
                              <img src={revenueLogo} />
                            </Box>
                            <Box className="detail-text">
                              <Box className="text-heading">
                                <Typography className="heading">
                                  {this.getContent(pageText.AnnualRevenueText,configJSON.AnnualRevenueTextArabic)}
                                </Typography>
                              </Box>
                              <Box className="text-value">
                                <Typography className="value">
                                  SAR {formatNumber(annualRevenue)}
                                </Typography>
                              </Box>
                            </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={6}
                            className="detail"
                          >
                            <Box className="detail-logo">
                              <img src={profitLogo} />
                            </Box>
                            <Box className="detail-text">
                              <Box className="text-heading">
                                <Typography className="heading">
                                  {this.getContent(pageText.annualProfitText,configJSON.annualProfitTextArabic)}
                                </Typography>
                              </Box>
                              <Box className="text-value">
                                <Typography className="value">
                                  SAR {formatNumber(annualProfit)}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            container
                            lg={4}
                            xl={4}
                            md={6}
                            sm={12}
                            xs={6}
                            className="detail"
                          >
                            <Box className="detail-logo">
                              <img src={mapLogo} />
                            </Box>
                            <Box className="detail-text">
                              <Box className="text-heading">
                                <Typography className="heading">
                                  {this.langConditionRendering(pageText.basedInText , configJSON.basedInTextArabic)}
                                </Typography>
                              </Box>
                              <Box className="text-value">
                                <Typography className="value">
                                  {basedIn}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={6}
                            className="detail"
                          >
                            <Box className="detail-logo">
                              <img src={usersIcon} />
                            </Box>
                            <Box className="detail-text">
                              <Box className="text-heading">
                                <Typography className="heading">
                                  {this.getContent(pageText.numberOfEmployeesText, configJSON.numberOfEmployeesTextArabic)}
                                </Typography>
                              </Box>
                              <Box className="text-value">
                                <Typography className="value">
                                  {employeesCount}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            container
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={6}
                            className="detail"
                          >
                            <Box className="detail-logo">
                              <img src={calendarLogo} />
                            </Box>
                            <Box className="detail-text">
                              <Box className="text-heading">
                                <Typography className="heading">
                                  {this.getContent(pageText.foundedInText,configJSON.foundedInTextArabic)}
                                </Typography>
                              </Box>
                              <Box className="text-value">
                                <Typography className="value">
                                  {foundedIn}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          {conditionSort(this.props.type?.toLowerCase() === "sme" || this.props.type === "س  مه", <Grid
                            item
                            container
                            lg={4}
                            xl={4}
                            md={6}
                            sm={12}
                            xs={6}
                            className="detail"
                          >
                            <Box className="detail-logo">
                              <img src={valuationLogo} />
                            </Box>
                            <Box className="detail-text">
                              <Box className="text-heading">
                                <Typography className="heading">
                                          {this.getContent(pageText.proposedEvalutionText, configJSON.proposedEvalutionTextArabic)}
                                </Typography>
                              </Box>
                              {this.renderValuation()}
                            </Box>
                          </Grid>, null)}
                        </Grid>
                      </Box>
                      </>
                      </Link>
                      :  
                      this.renderDealClosedBox()
                        }         
                      {this.cardFooter()}
                    </Box>
                  </>
                ),
              ]
            )}
          </>
        ) : (
          this.getGridRecords()
        )}

        <BookmarkPopup
          data-testid = "bookmarkPopup"
          bookmarkModalState={this.state.bookmarkModal}
          closeBookmark={this.closeBookmark}
          handleBookmarkCreate={this.handleBookmarkCreate}
          bookmarkCreate={this.state.bookmarkCreate}
          bookmarkList={this.state.bookmarkList}
          handleListSubmit={this.handleListSubmit}
          bookmarkCatalogue={this.bookmarkCatalogue}
          classes={classes}
          language={language}
          pageText={this.getContent(pageText, { ...pageText, bookmarkModalTitle: configJSON.bookmarkModalTitleArabic, addListTxt: configJSON.addListTxtArabic }) as IPageText}
        />
      </>
    );
  }
}

export const styles = () =>
  createStyles({
    tooltipStyle: {
      maxWidth: "100px",
    },
    cardContainer: {
      boxSizing: "border-box",
      maxWidth: "1200px",
      width: "100%",
      borderRadius: "30px",
      border: "2px solid #8C8C8C",
      padding: "25px 30px",
      cursor: "pointer",
      gap: "10px",
      transition: "box-shadow 0.3s ease-in-out",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0)",
      "&:hover": {
        boxShadow: "rgba(140, 140, 140, 0.80) 0px 6px 6px",
      },
      "& .closedCardBox": {
        textDecoration: "none",
        color: "inherit"
      },
      "& .contain-box": {
        padding: "25px",
        borderBottom: "1px solid black",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        "& .description": {
          minWidth: "336px",
          width: "336px",
          "& .text": {
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
          },
          "@media (max-width:759px)": {
            minWidth: "200px",
            width: "100%",
          },
        },
        "& .details": {
          gap: "1rem 0px",
          "& .detail": {
            display: "flex",
            boxSizing: "border-box",
            justifyContent: "flex-start",
            "& .detail-logo": {
              marginRight: "12px",
              marginLeft:"12px",
              "& img": {
                height: "24px",
              },
            },
            "& .detail-text": {
              "& .text-heading": {
                paddingBottom: "10px",
                "& .heading": {
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                },
              },
              "& .text-value": {
                "& .value": {
                  fontSize: "14px",
                  fontWeight: 700,
                  fontFamily: "Montserrat",
                  wordBreak: "break-all",
                  "& span": {
                    fontSize: "10px",
                    color: "#8C8C8C",
                  },
                },
              },
            },
          },
        },
      },
      "& .closedText": {
        color: "rgba(38,38,38,0.5) !important",
        "& .detail-logo": {
          "& img": {
            opacity: 0.5
          }
        }
      },
      "@media (max-width:767px)": {
        "& .contain-box": {
          padding: "25px"
        },
        "& .details": {
          gap: "20px",
          "& .detail": {
            "& .detail-logo": {
              width: "27px !important",
            },
            "& .detail-text": {
              width: "calc(100% - 50px)",
            },
          },
        },
      },
      "@media (max-width:600px)": {
        margin: "0rem auto",
        borderRadius: "12px",
        padding: "1.5rem",
        "& .contain-box": {
          padding: "1rem 5px",
          display: "block",
          "& .description": {
            minWidth: "auto !important",
            marginBottom: "30px",
            width: "100%",
            "& .text": {
              fontSize: "10px !important",
            },
          },
          "& .details": {
            "& .detail": {
              paddingBottom: "17px !important",
              "& .detail-text": {
                "& .text-heading": {
                  paddingBottom: "0px !important",
                  "& .heading": {
                    fontSize: "8px !important",
                    lineHeight: "18px",
                  },
                },
                "& .text-value": {
                  "& .value": {
                    fontSize: "8px !important",
                    "& span": {
                      fontSize: "6px !important",
                    },
                  },
                },
              },
            },
          },
        },
      },
      "@media (max-width:450px)": {
        padding: "20px 15px !important"
      }
    },
    disabledCardContainer: {
      "&:hover": {
        boxShadow: "none !important"
      }
    },
    smallCardContainer: {
      position: "relative",
      padding: "min(10%,1rem)",
      overflow:"hidden",
      margin: "20px",
      maxWidth: "400px",
      minWidth: "400px",
      border: "1px solid #8c8c8c",
      boxSizing: "border-box",
      borderRadius: "12px",
      transition: "box-shadow 0.3s ease-in-out",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0)",
      height: "460px",
      "&:hover": {
        boxShadow: `rgba(140, 140, 140, 0.80) 0px 6px 6px`,
      },
      "& .contain-box": {
        padding: "20px 6px 10px",
        "& .description": {
          minWidth: "286px",
          paddingBottom: "30px",
          "& .text": {
            fontFamily: "Montserrat",
            fontSize: "14px !important",
            lineHeight: "18px",
          },
        },
      },
      "& .closedText": {
        "& .detail-logo": {
          "& img": {
            opacity: 0.5
          }
        },
        color: "rgba(38,38,38,0.5) !important"
      },
      "& .details": {
        display: "flex",
        justifyContent: "flex-end",
        "& .detail": {
          paddingBottom: "23px",
          "& .detail-logo": {
            marginRight: "8px",
            "& img": {
              height: "20px",
            },
          },
          "& .detail-text": {
            "& .value": {
              fontSize: "12px !important",
              fontWeight: 400,
              fontFamily: "Montserrat",
              wordBreak: "break-all",
              "& span": {
                fontSize: "10px !important",
                color: "#8C8C8C",
              },
            },
          },
        },
      },
      "@media (max-width:600px)": {
        padding: "16px 24px",
        margin: "11px",
        maxWidth: "244px",
        minWidth: "244px",
        "& .contain-box": {
          padding: "24px 0",
          "& .description": {
            width: "100%",
            minWidth: "auto !important",
            "& .text": {
              fontSize: "10px !important",
            },
          },
        },
        "& .details": {
          "& .detail": {
            "& .detail-text": {
              "& .value": {
                fontSize: "8px !important",
                "& span": {
                  fontSize: "6px !important",
                },
              },
            },
          },
        },
      },
    },
    smallCardStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "20px",
      flexWrap: "wrap",
      "& .left-side": {
        display: "flex",
        flexGrow: 1,
        justifyContent: "start",
        gap: "4px",
        alignItems: "center",
        "& img": {
          height: "48px",
        },
        "& .title": {
          fontWeight: "bold",
          fontSize: "1rem",
          fontFamily: "Montserrat",
        },
      },
      "& .closedLeftSide":{
        "& .title": {
          color: "#D9D9D9 !important"
        },
        "& img": {
          filter: "grayscale()",
          opacity: 0.5
        }
      },
      "& .dealClosedBox": {
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        borderRadius: "30px",
        justifyContent: "center",
        minWidth: "max-content",
        padding: "10px 8px",
        border: "1px solid #5412FC",
        background: "rgba(84,18,252,0.5)",
        "& .MuiTypography-body1": {
          color: "#5412FC",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Montserrat"
        }
      },
      "& .right-side": {
        display: "flex",
        justifyContent: "start",
        gap: "5px",
        alignItems: "center",
        padding: "6px 8px",
        border: "2px solid",
        borderRadius: "30px",
        minWidth: "90px",
        boxSizing: "border-box",
        "& .img-box": {
          padding: "5px",
          borderRadius: "100%",
          backgroundColor: "transparent",
          "& img": {
            height: "10px",
          },
        },
      },  
      "& .closedRightSide": {
        border: "2px solid #8C8C8C !important",
        "& .img-box": {
          backgroundColor: "#8C8C8C !important",
          "& img": {
            filter: "grayscale()"
          }
        },
        "& .type": {
        color: "#8C8C8C !important",
        fontSize: "10px",
        fontFamily: "Montserrat",
        fontWeight: 600
      }
    },
      "@media (max-width:600px)": {
        "& .left-side": {
          gap: "11px",
          "& .title": {
            fontSize: "16px !important",
          },
          "& img": {
            height: "35px !important",
          },
        },
        "& .right-side": {
          border: `1px solid`,
          padding: "5px",
          gap: "5px",
          minWidth: "90px",
          "& .type": {
            fontSize: "10px !important",
          },
          "& .img-box": {
            padding: "2px !important",
            "& img": {
              height: "12px !important",
            },
          },
        },
        "& .dealClosedBox": {
          padding: "7px 5px",
          "& .MuiTypography-body1": {
            fontSize: "8px !important"
          },
        },
        "& .closedRightSide": {
          border: "1px solid #8C8C8C !important"
        }
      },
    },
    cardTitleStyle: {
      display: "flex",
      alignItems: "center",
      gap: "25px",
      "& .left-side": {
        display: "flex",
        justifyContent: "start",
        gap: "18px",
        alignItems: "center",
        flexGrow: 1,
        "& img": {
          height: "66px",
        },
        "& .title": {
          fontWeight: "bold",
          fontFamily: "Montserrat",
          fontSize: "28px",
        },
      },
      "& .closedLeftSide": {
        "& img": {
          opacity: 0.5,
          filter: "grayscale()"
        },
        "& .title": {
          color:"#D9D9D9 !important"
        }
      },
      "& .dealClosedBox": {
        boxSizing: "border-box",
        borderRadius: "30px",
        display:"flex",
        alignItems: "center",
        padding: "10px",
        justifyContent: "center",
        width: "fit-content",
        background: "rgba(84,18,252,0.5)",
        border: "1px solid #5412FC", 
        "& .MuiTypography-body1": {
          fontFamily: "Montserrat",
          fontWeight: 600,
          fontSize: "18px",
          color: "#5412FC",
        }
      },         
      "& .right-side": {
        display: "flex",
        justifyContent: "start",
        gap: "10px",
        alignItems: "center",
        padding: "7px",
        border: "2px solid",    
        borderRadius: "30px",
        minWidth: "170px",
        "& .img-box": {
          backgroundColor: "transparent",
          borderRadius: "100%",
          padding: "5px",   
          "& img": {
            height: "20px",
          },
        },
        "& .type": {
          fontWeight: 600,
          fontSize: "18px",
          color: "#333",
          fontFamily: "Montserrat",   
        },
      },
      "& .closedRightSide": {
        border: "2px solid #8C8C8C !important",
        minWidth: "0px !important",
        width: "fit-content",
        boxSizing: "border-box",
        "& .type": {
          color: "#8C8C8C !important"
        },
        "& .img-box": {
          backgroundColor: "#8C8C8C !important",
          "& img": {
            filter: "grayscale()"   
          }
        }
      },
      "@media (max-width:600px)": {
        gap: "15px",
        flexWrap: "wrap",
        "& .left-side": {
          gap: "11px",
          "& img": {
            height: "35px !important",
          },
          "& .title": {
            fontSize: "20px !important",
          },
        },
        "& .closedRightSide": {
          border: "1px solid #8C8C8C !important",
          minWidth: "0px !important",
          width: "100px"
        },
        "& .right-side": {
          gap: "5px",
          padding: "5px",
          minWidth: "90px",   
          border: `1px solid`,
          "& .img-box": {
            padding: "2px !important",
            "& img": {
              height: "12px !important",
            },
          },
          "& .type": {
            fontSize: "10px !important",
          },
        },
        "& .dealClosedBox": {
          padding: "5px",
          minWidth: "0px !important",
          background: "rgba(84,18,252,0.5)",
          width: "100px",
          border: "1px solid #5412FC",
          "& .MuiTypography-body1": {
            fontSize: "10px !important"
          }
        }
      },
      "@media (max-width:450px)": {
        gap: "5px",
        flexWrap: "wrap",
        "& .left-side": {
          "& .title": {
            fontSize: "16px !important",
          },
        },
        "& .dealClosedBox": {
          width: "80px"
        },
        "& .right-side": {
          minWidth: "auto",
        },
        "& .closedRightSide": {
          minWidth: "0px !important",
          width: "80px",
          "& img":{
            display: "none"
          }
        }
      },
    },

    cardFooterStyle: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      paddingTop: "18px",
      gap: "36px",
      paddingLeft: "15px",
      width: "auto !important",
      "& .bookmarkIcon:hover": {
        backgroundImage: `url(${bookMarkHover})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "22px",
        "& img": {
          opacity: 0,
        },
      },
      "& .flagIcon:hover": {
        backgroundImage: `url(${flagHover})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "4px",
        backgroundSize: "23px",
        "& img": {
          opacity: 0,
        },
      },
      "& .shareIcon:hover": {
        backgroundImage: `url(${shareHover})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "28px 24px",
        "& img": {
          opacity: 0,
        },
      },
      "& .closedDealBtns": {
        opacity: 0.5,
        "& img": {
          filter: "grayscale()"
        }
      },
      "& img": {
        height: "30px",
      },
      "& [data-tooltip]": {
        position: "relative",
        zIndex: "2",
        cursor: "pointer",
        "&:hover:before": {
          visibility: "visible",
          MsFilter: '"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"',
          filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)",
          opacity: "1",
        },
      },
      "& [data-tooltip]:before": {
        position: "absolute",
        top: "95%",
        left: "50%",
        marginBottom: "5px",
        marginLeft: "-80px",
        padding: "0px 10px",
        width: "160px",
        borderRadius: "3px",
        backgroundColor: "transparent",
        color: "#000",
        content: "attr(data-tooltip)",
        textAlign: "center",
        fontSize: "14px",
        fontFamily: "Montserrat",
        visibility: "hidden",
        textTransform: "none",
        MsFilter: '"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"',
        filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)",
        opacity: "0",
        pointerEvents: "none",
      },

      "& button": {
        background: "transparent",
        padding: "0px",
        border: "0px",
        minWidth: "0px",
        "&:hover": {
          backgroundColor: "#fff",
        },
      },
      "& .original-listing": {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        flex: "1",
        "& img": {
          height: "32px",
        },
        "& .listing-text": {
          color: "#FF7575",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 500,
        },
      },
      "& .closedOriginalListing": {
        "& .listing-text": {
          color: "#8C8C8C !important"
        },
        "& img": {
          filter: "grayscale()"
        }
      },
      "@media (max-width:600px)": {
        paddingTop: "17px",
        gap: "9px",
        "& .bookmarkIcon:hover": {
          backgroundSize: "contain",
        },
        "& .flagIcon:hover": {
          backgroundSize: "contain",
          backgroundPosition: "left",
        },
        "& .shareIcon:hover": {
          backgroundSize: "contain",
        },
        "& img": {
          height: "18px",
        },
        "& .original-listing": {
          "& img": {
            height: "18px",
          },
          "& .listing-text": {
            fontSize: "10px !important",
          },
        },
      },
    },

    smallCardFooter: {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100% !important",
      padding: "18px 24px",
      boxSizing: "border-box",
      paddingTop: "17px",
      gap: "20.11px",
      "& img": {
        height: "28px",
        width: "28px",
      },
      "& button": {
        background: "transparent",
        padding: "0px",
        border: "0px",
      },
      "& .bookmarkIcon:hover": {
        transform: "scale(0.893)",
        backgroundSize: "contain",
      },
      "& .flagIcon:hover": {
        backgroundSize: "contain",
        backgroundPosition: "3px",
        transform: "scale(0.88)",
      },
      "& .shareIcon:hover": {
        backgroundSize: "contain",
        transform: "scale(0.82)",
      },
      "& .closedDealBtns": {
        opacity: 0.5,
        "& img": {
          filter: "grayscale()"
        }
      },
      "& [data-tooltip]::before": {
        fontSize: "8px",
      },
      "& .original-listing": {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        flex: "1",
        "& img": {
          height: "28px",
        },
        "& .listing-text": {
          color: "#FF7575",
          fontSize: "10px",
          fontFamily: "Montserrat",
          fontWeight: 500,
        },
      },
      "& .closedOriginalListing": {
        "& img": {
          filter: "grayscale()"
        },
        "& .listing-text": {
          color: "#8C8C8C !important"
        }
      }
    },

    bookmarkModal: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    infoModalPaper: {
      backgroundColor: "white",
      width: "700px",
      borderRadius: "20px",
      boxSizing: "border-box",
      border: "1px solid #000",
      padding: "28px 32px",
      margin: "auto",
      "@media (max-width:1220px)": {
        height: "auto",
        width: "80%",
      },
    },
    headerAction: {
      display: "flex",
      justifyContent: "space-between",
      alignItem: "center",
    },
    modalTitle: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "20px",
      marginBottom: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
    },
    closeInfoBtn: {
      width: "24px",
      height: "24px",
      minWidth: "24px",
      padding: "0px",
      marginBottom: "2px",
    },
    cardFooterFlag: {
      justifyContent: "space-between",
      padding: "20px 0px",
      "& .shared": {
        width: "26px",
        height: "32px",
      },
    },
    bookmarkModalBody: {
      borderRadius: "20px",
      border: "1px solid #8C8C8C",
      padding: "24px",
      overflowY: "auto",
      width: "100%",
      boxSizing: "border-box",
      maxHeight: "489px",
      "& *": {
        boxSizing: "border-box",
      },
    },
    flagModalBodyInner: {
      width: "calc(100% - 4px)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "45px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9", // Color of the scrollbar thumb
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "darkgrey", // Color of the scrollbar thumb on hover
      },
      "& > div": {
        display: "flex",
        flexDirection: "column",
        gap: "28px",
        alignItems:"flex-start"
      },
    },
    flaArchiveModalBody: {
      border: "0px",
      height: "auto",
    },
    archiveModalPaper: {
      height: "auto",
      opacity: "1 !important",
      maxHeight: "300px",
      //@ts-ignore
      visibility: "visible !important",
    },
    bookmarkModalBodyInner: {
      width: "calc(100% - 4px)",
      padding: "0",
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "45px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9", // Color of the scrollbar thumb
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "darkgrey", // Color of the scrollbar thumb on hover
      },
    },
    flaArchiveModalInner: {
      padding: "0px",
      flexDirection: "row",
    },
    brandItems: {
      display: "flex",
      alignItems: "center",
      gap: "18px",
      "& img": {
        height: "56px",
      },
      "& .title": {
        fontWeight: "bold",
        fontSize: "22px",
        fontFamily: "Montserrat",
      },
    },
    bookmarkList: {
      padding: "0px",
      display: "flex",
      gap: "18px",
      alignItems: "center",
      textTransform: "none",
      "& form": {
        marginBottom: "0px",
      },
      "& span": {
        display: "flex",
        gap: "18px",
        justifyContent: "left",
      },
      "&:hover": {
        background: "transparent",
      },
    },
    archiveBlock: {
      gap: "32px !important",
      //@ts-ignore
      flexDirection: "row !important",
      justifyContent: "center",
      "& button": {
        borderRadius: "12px",
        fontFamily: "Montserrat",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",
        padding: "14px 24px",
        textTransform: "none",
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
          background: "#FFF",
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    archiveActive: {
      border: "2px solid #10BFB8",
      background: "#FFF",
      color: "#10BFB8",
    },
    archiveDeActive: {
      border: "2px solid #FF7575",
      background: "#FFF",
      color: "#FF7575",
    },
    flagList: {
      padding: "0px",
      display: "flex",
      gap: "60px",
      alignItems: "center",
      textTransform: "none",
      "& span": {
        display: "flex",
        gap: "1rem",
        justifyContent: "left",
      },
      "&:hover": {
        background: "transparent",
      },
    },
    formControl: {
      height: "52px",
      "& > div": {
        margin: "0px",
        "&:before": {
          display: "none",
        },
        "&:after": {
          display: "none",
        },
        "& fieldset": {
          borderRadius: "12px",
          border: "1px solid #8C8C8C",
        },
        "& input": {
          background: "#FFF",
          height: "52px",
          fontSize: "18px",
        },
      },
    },
    bookMarkedIcon: {
      borderRadius: "8px",
      width: "44px",
      height: "44px",
      background: "rgba(84, 18, 252, 0.20)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",

      "& img": {
        width: "50%"
      }
    },
    flagListIcon: {
      width: "24px",
      height: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      "& img": {
        height: "100%",
      },
    },
    flagLabel: {
      color: "#000",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    bookmarkLabel: {
      color: "#000",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    catalogueShareBox: {
      width: "100%",
      border: "2px solid #8C8C8C",
      cursor: "pointer",
      margin: "0 auto",
      padding: "32px 42px 0",
      maxWidth: "1200px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0)",
      boxSizing: "border-box",
      transition: "box-shadow 0.3s ease-in-out",
      borderRadius: "30px",
    },
    flagTitle: {
      color: "#262626",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      marginBottom: "1rem",
    },
    flagBoxInner: {
      display: "flex",
      gap: "1rem",
      alignItems: "center",
      justifyContent: "space-between",
    },
    leftBox: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      "& p": {
        color: "#000",
        maxWidth: "303px",
        fontFamily: "Montserrat",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
      },
    },
    iconContainer: {
      color: "#9871FD",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      gap: "16px",
      marginBottom: "16px",
    },
    iconContainerReport: {
      color: "#FF7575",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      gap: "16px",
      marginBottom: "16px",
    },
    dataContainer: {
      color: "#000",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "26px",
      "& span": {
        display: "block",
        textDecoration: "underline",
      },
    },
    rightBox: {
      display: "flex",
      gap: "42px",
    },
    rightBoxInnerFirst: {
      borderRadius: "20px",
      padding: "18px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      border: "1px solid #8C8C8C",
      boxSizing: "border-box",
      width: "279px",
      cursor: "pointer",
      "& *": {
        cursor: "pointer",
      },
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      },
    },
    rightBoxShare: {
      display: "flex",
      gap: "18px 58px",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      maxWidth: "452px",
    },
    sharedBtn: {
      width: "70px",
      height: "70px",
      flexShrink: 0,
      borderRadius: "16px",
      border: "2px solid #262626",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "0.3s",
      "& label": {
        width: 0,
        opacity: "0",
        fontFamily: "Montserrat",
        position: "absolute",
      },
      "& .btnHover": {
        position: "absolute",
        opacity: 0,
        visibility: "hidden",
        transition: "0.3s",
      },
      "&:hover": {
        color: "#5412FC",
        background: "#fff",
        zIndex: "2",
        borderColor: "transparent",
        "& .btnOriginal": {
          position: "absolute",
          opacity: 0,
          visibility: "hidden",
          transition: "0.3s",
        },
        "& .btnHover": {
          display: "flex",
          position: "absolute",
          alignItems: "center",
          height: "calc(100% + 4px)",
          border: "2px solid #5412FC !important",
          borderRadius: "12px",
          textTransform: "none",
          color: "#5412FC !important",
          fontSize: "20px !important",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "500",
          padding: "8px 14px !important",
          boxSizing: "border-box",
          gap: "8px",
          top: "-2px",
          background: "#fff !important",
          opacity: 1,
          visibility: "visible",
          minWidth: "auto",
          boxShadow: "0px 3px 3px #ccc",
          "& > span": {
            gap: "8px",
          },
        },
        "& label": {
          width: "auto",
          opacity: "1",
          position: "static",
          whiteSpace: "nowrap",
        },
      },
    },

    catalogShareGrid: {
      maxWidth: "400px",
      minWidth: "400px",
      boxSizing: "border-box",
      border: "1px solid #8c8c8c",
      transition: "box-shadow 0.3s ease-in-out",
      margin: "20px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "24px 24px 14px",
      "& .cardTitleStyle label": {
        fontSize: "20px",
        marginBottom: "12px",
      },
      "& .flagBoxInner": {
        "& .cardTitleStyle label": {
          fontSize: "20px",
          marginBottom: "12px",
        },
        "& .leftBox": {
          "& p": {
            fontSize: "12px !important",
            maxWidth: "100%",
            fontFamily: "Montserrat",
            lineHeight: "18px",
          },
        },
        "& .rightBox": {
          gap: "60px",
          display: "flex",
          margin: "21px 20px",
        },
        "& .rightBoxInnerFirst": {
          padding: "15px",
          "& p": {
            fontSize: "10px",
            lineHeight: "18px",
          },
          "& > span": {
            justifyContent: "center",
            gap: "8px",
            marginBottom: "10px",
            "& img": {
              width: "20px",
              height: "20px",
            },
            "& span": {
              fontSize: "14px",
            },
          },
        },
      },
      "& .catalogueBoxInner": {
        borderRadius: "12px",
        flexDirection: "column",
        gap: "16px",
        padding: "0px",
        marginBottom: "11px",
        "& .leftBox": {
          width: "100%",
          "& p": {
            fontSize: "12px",
            maxWidth: "100%",
            lineHeight: "18px",
          },
        },
      },
      "& .rightBoxShare": {
        justifyContent: "space-between",
        gap: "20px 48px",
        padding: "0px 20px",
      },
      "& .smallCardFooterFlag": {
        width: "100%",
        justifyContent: "space-between",
        paddingBottom: "6px",
        paddingTop: "17px",
      },
      "& .brandItems": {
        "& img": {
          height: "32px",
          width: "32px",
        },
        "& .title": {
          fontSize: "20px",
        },
      },
      "& .shareBtn": {
        width: "48px",
        height: "48px",
        minWidth: "48px",
        borderRadius: "12px",
        "&:hover .btnHover": {
          gap: "2px",
          padding: "8px 10px !important",
          "&  > span": {
            gap: "2px",
          },
        },
        "&:first-child:hover .btnHover": {
          left: "-2px",
        },
        "&:nth-child(3):hover .btnHover": {
          right: "-2px",
        },
        "&:nth-child(4):hover .btnHover": {
          left: "-2px",
        },
        "&:nth-child(6):hover .btnHover": {
          right: "-2px",
        },
        "& svg": {
          width: "24px",
          height: "24px",
        },
        "& label": {
          fontSize: "14px",
        },
      },
      "& .flaggedIcon": {
        "& .flagIcon:hover": {
          backgroundPosition: "3px",
          backgroundSize: "contain",
          transform: "scale(1)",
        },
      },
    },
    "@media(max-width: 950px)": {
      cardContainer: {
        padding: "25px",
        "& .contain-box": {
          flexDirection: "column",
          gap: "20px",
          "& .description": {
            width: "100%",
          },
        },
        "& .contain-box .details .detail": {
          width: "33.33%",
          flexBasis: "33.33%",
        },
        "& .contain-box .details .detail .detail-text .text-heading .heading": {
          fontSize: "12px !important",
        },
        "& .contain-box .details .detail .detail-text .text-heading .text": {
          fontSize: "10px !important",
        },
      },
    },
    "@media(max-width: 767px)": {
      catalogShareGrid: {
        maxWidth: "292px",
        minWidth: "292px",
        margin: "11px",
        "& .flagBoxInner": {
          "& .rightBox": {
            margin: "21px 0px",
            gap: "10px",
          },
        },
        "& .rightBoxShare": {
          padding: "0px 10px",
          gap: "20px 8px",
        },
      },
      smallCardStyle: {
        marginBottom: "0px",
      },
      bookMarkedIcon: {
        width: "51.467px",
        height: "51.467px",
        "& img": {
          width: "25.733px",
          height: "25.733px",
        },
      },
      archiveBlock: {
        "& button": {
          fontSize: "14px",
        },
      },
      catalogueBoxInner: {
        padding: "0px",
      },
      catalogueShareBox: {
        borderRadius: "12px",
        padding: "25px 30px 0 25px",
      },
      modalTitle: {
        fontSize: "20px",
        marginBottom: "24px",
      },
      bookmarkModalBody: {
        height: "222px",
        width: "100%",
        padding: "14px 0px",
      },
      flagModalBody: {
        height: "184px",
      },
      flagList: {
        gap: "15px",
        "& span": {
          gap: "15px",
        },
      },
      flagModalBodyInner: {
        "& > div": {
          padding: "1px 15px",
          gap: "30px",
        },
      },
      flagListIcon: {
        width: "26px !important",
        height: "26px",
      },
      infoModalPaper: {
        padding: "33px",
      },
      bookmarkModalBodyInner: {
        gap: "19.3px",
        padding: "0px 17px",
      },
      flagLabel: {
        fontSize: "14px !important",
        textAlign: "left",
      },
      bookmarkList: {
        gap: "17.54px",
        "& span": {
          gap: "17.54px",
        },
      },
      formControl: {
        height: "44px",
        "& div input": {
          height: "44px",
          fontSize: "14px",
        },
      },
      flagBoxInner: {
        flexDirection: "column",
        gap: "17px",
      },
      rightBox: {
        gap: "27px",
        width: "100%",
      },
      rightBoxInnerFirst: {
        width: "50%",
        padding: "18px 17px",
        borderRadius: "8px",
      },
      leftBox: {
        width: "100%",
        "& p": {
          fontSize: "10px !important",
          lineHeight: "18px",
        },
      },
      ".leftBox": {
        width: "100%",
        "& p": {
          fontSize: "10px !important",
          lineHeight: "18px",
        },
      },
      cardFooterFlag: {
        padding: "13px 0px",
      },
      brandItems: {
        "& img": {
          height: "35px",
        },
        "& .title": {
          fontSize: "14px !important",
        },
      },
      flagTitle: {
        fontSize: "14px !important",
        marginBottom: "9px",
      },
      iconContainer: {
        gap: "8px",
        fontSize: "12px !important",
        justifyContent: "center",
        "& img": {
          width: "20px",
          height: "20px",
        },
      },
      iconContainerReport: {
        fontSize: "12px !important",
        gap: "8px",
        justifyContent: "center",
        "& img": {
          width: "20px",
          height: "20px",
        },
      },
      archiveModalPaper: {
        maxHeight: "280px",
      },
      dataContainer: {
        fontSize: "8px !important",
        lineHeight: "15px",
        "& span": {
          fontSize: "8px !important",
        },
      },
      rightBoxShare: {
        justifyContent: "space-between",
        gap: "14px 20%",
      },
      sharedBtn: {
        width: "48px",
        height: "48px",
        minWidth: "48px",
        borderRadius: "12px",
        "&:first-child:hover .btnHover": {
          left: "-2px",
        },
        "&:nth-child(3):hover .btnHover": {
          right: "-2px",
        },
        "&:nth-child(4):hover .btnHover": {
          left: "-2px",
        },
        "&:nth-child(6):hover .btnHover": {
          right: "-2px",
        },
        "& svg": {
          width: "24px",
          height: "24px",
        },
        "& label": {
          fontSize: "14px",
        },
      },
      "& [data-tooltip]:before": {
        fontSize: "10px !important",
      },
    },
    "@media(max-width: 600px)": {
      smallCardContainer: {
        minWidth: "292px",
        maxWidth: "292px",
        "& .details": {
          "& .detail": {
            width: "50%",
            flexBasis: "50%",
            maxWidth: "100%",
          },
        },
      },
    },
    "@media(max-width: 460px)": {
      rightBoxShare: {
        gap: "14px 20px",
      },
      cardContainer: {
        "& .contain-box": {
          "& .details": {
            "& .detail ": {
              flexBasis: "50%",
              width: "50%",
              "& .detail-logo": {
                width: "24px !important",
              },
            },
          },
        },
        "& .contain-box .details .detail .detail-text .text-heading .heading": {
          fontSize: "8px !important",
        },
      },
    },
  });

export default withStyles(styles)(CatalogueList);
