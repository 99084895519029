import { createStyles, Theme } from "@material-ui/core";
export const Styles = (theme: Theme) =>
  createStyles({
    select_div: {
      width: "808px",
      height: "444px",
      gap: "0px",
      borderRadius: "20px",
      border: "1px solid black",
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%"
      }
    },
    select_inner: {
      padding: "2rem",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem"
      }
    },
    select_h: {
      marginTop: "58px",
      marginLeft: "28px",
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: "39.01px",
      textAlign: "left"
    },
    select_icon: {
      display: "flex",
      justifyContent: "space-between",
      
    },
    subscribe_wrapper: {
      margin: "0 auto",
      padding: "32px 0",
      width: "calc(100% - 200px)",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      gap: "32px"
    },
    pageTitle: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
    },
    pageDetails: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "1.8rem",
    },
    heading: {
      borderBottom: "2px solid #5412FC",
      paddingBottom: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      color: "#5412FC",
      fontFamily: "Montserrat",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: 700,
      margin: 0,
      lineHeight: "normal",
    },
    priceLabel: {
      color: "#5412FC",
      fontFamily: "Montserrat",
      fontSize: "26px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      "& span": {
        color: "#5412FC",
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "normal",
        lineHeight: "normal",
      },
    },
    childHeading: {
      color: "#262626",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      display: "block",
      marginBottom: "26px",
      lineHeight: "24px",
    },
    featureList: {
      display: "flex",
      flexWrap: "wrap",
      comuns: "3",
      gap: "32px 18px",
      justifyContent: "center",
    },
    featureBlock: {
      borderRadius: "20px",
      border: "1px solid #8C8C8C",
      background: "#FFF",
      width: "31.8%",
      padding: "18px",
      display: "flex",
      gap: "28px",
      minHeight: "192px",
      boxSizing: "border-box",
      "& img": {
        width: "50px",
        flex: "0 0 50px",
        height: "50px",
      },
    },
    featureTitle: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      margin: "6px 0px 24px",
      lineHeight: "normal",
    },
    featureDetails: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      wordBreak: "break-word",
    },
    outersubscribe_wrapper: {
      padding: "16px 7px 16px 0px",
      borderRadius: "20px",
      border: "1px solid #8C8C8C",
      boxSizing: "border-box",
      overflow: "hidden"
    },
    innersubscribe_wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "24px 48px",
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "34px",
      boxSizing: "border-box",
      background: "#FFF",
      height: "auto",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "45px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9", // Color of the scrollbar thumb
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "darkgrey", // Color of the scrollbar thumb on hover
      },
    },
    footersubscribe_wrapper: {
      display: "flex",
      justifyContent: "center",
      gap: "160px",
      marginTop: "3rem",
    },
    expiredActions:{
      display: "flex",
      justifyContent: "space-around",
      marginTop: "70px",
    },
    subscribeButton: {
      padding:"0 30px",
      borderRadius: "12px",
      border: "2px solid #10BFB8",
      background: "#10BFB8",
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "34px",
      minWidth: "200px",
      height: "48px",
      textTransform: "capitalize",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        background: "#10BFB8",
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      },
    },
    ActiveFeatureListWrapper: {
      display: "flex",
      padding: "50px 0 50px 0",
    },
    ActiveFeatureList: {
      display: "flex",
      flexWrap: "wrap",
      comuns: "3",
      gap: "24px 24px",
      justifyContent: "flex-start",
    },
    ActiveFeatureListTitle: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      margin: "6px 24px 0 0",
      lineHeight: "normal",
    },
    OuterRenewCard: {
      padding: "50px 0 100px 0",
    },
    renewCard: {
      padding: "40px 60px",
      borderRadius: "20px",
      border: "1px solid #FF7575",
      boxSizing: "border-box",
      "& h1": {},
    },
    alertDiv: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "20px",
      paddingBottom: "30px",
    },
    alertPara: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
    cancelButton: {
      padding:"0 30px",
      marginLeft: "2px",
      marginRight: "2px",
      borderRadius: "12px",
      border: "2px solid #FF7575",
      color: "#FF7575",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "34px",
      minWidth: "200px",
      height: "48px",
      textTransform: "capitalize",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        background: "#FF7575",
        color: "#FFF",
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      },
    },
    activeBorder: {
      border: "2px solid transparent",
      padding: "20px",
      overflow: "hidden",
      background:
        "linear-gradient(to left, #fff, #fff),linear-gradient(to left, #5412FC, #00F8A2)",
      borderRadius: "20px",
      backgroundClip: "padding-box , border-box",
      backgroundOrigin: "padding-box , border-box",
    },
    expiredBorder: {
      border: "2px solid transparent",
      padding: "20px",
      overflow: "hidden",
      background:
        "linear-gradient(to left, #fff, #fff),linear-gradient(to left, #5412FC, #FF7575)",
      borderRadius: "20px",
      backgroundClip: "padding-box , border-box",
      backgroundOrigin: "padding-box , border-box",
    },
    activeIcon: {
      border: "2px solid rgb(0, 0, 0)",
      borderRadius: "30px",
      padding: "5px",
      maxWidth: "100px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      justifyContent: "flex-start",
      
    },
    plan:{
      padding:"20px 60px",
      "& .arabicDate": {
        visibility: "hidden",
        marginLeft: "-15px"
      },
      "& .planName":{
        fontWeight:"600"
      }
    },
    days:{
      display:"flex",
      alignItems: "center",
      gap: "30px",
      "& p":{
        fontSize:"1.7rem"
      }
    },
    PaymentFormWrapper:{
      padding:"30px 40px",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 15px"
      },
      "& input": {
        width: "100%",
        borderRadius: 12,
        border: "1px solid #8c8c8c",
        color: "#262626",
        fontSize: 20,
        padding: "11px 18px",
        fontFamily: "Montserrat, sans-serif",
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "45px"
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#D9D9D9",
          borderRadius: "4px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "darkgrey"
        },
        [theme.breakpoints.down("sm")]: {
          borderRadius: 8,
          fontSize: 10,
          padding: "9px 12px",
          minHeight: "32px"
        },
        '&[type="date"]': {
          "&::-webkit-calendar-picker-indicator": {
            cursor: "pointer"
          },
          [theme.breakpoints.down("sm")]: {
            padding: "4px 12px"
          }
        }
      },
      "& label": {
        display: "block",
        color: "#262626",
        fontFamily: "Montserrat, sans-serif",
        marginBottom: 24,
        [theme.breakpoints.down("sm")]: {
          marginBottom: 4
        }
      }
    },
    inputWrapper: {
      fontSize: 24,
      fontWeight: 500,
      marginBottom: 60,
      flex: 1,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        marginBottom: 15
      },
    },
    flexRow:{
      display:"flex",
      justifyContent:"space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      },
    },
    paymentBorder: {
      padding: "60px",
      borderRadius: "20px",
      border: "1px solid #8C8C8C",
      boxSizing: "border-box",
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
      },
      "& ul":{
        listStyle: "none",
        padding:"0px",
        display:"grid",
        gridTemplateColumns:"repeat(2, 1fr)",
        gap:"10px",
        [theme.breakpoints.down("sm")]: {
          gap:"5px"
        }
      }
    },
    cardAccept:{
      padding:"30px 40px",
      borderRight: "1px solid #8C8C8C",
      [theme.breakpoints.down("sm")]: {
        padding:"10px 15px",
        border:"none"
      }
    },
    requiredAsterisk: {
      display: "inline-block",
      color: "#ff7575"
    },



    
      cnpBillingCheckoutWrapper: {
        position: 'relative'
      },
      cnpBillingCheckoutHeader: {
        width: '100%',
        borderBottom: '1px solid #c0c0c0',
        marginBottom: '10px'
      },
      cnpBillingCheckoutLeft: {
        width: '240px',
        marginLeft: '5px',
        marginBottom: '10px',
        border: '1px solid #c0c0c0',
        display: 'inline-block',
        verticalAlign: 'top',
        padding: '10px'
      },
      cnpBillingCheckoutRight: {
        width: '50%',
        marginLeft: '5px',
        border: '1px solid #c0c0c0',
        display: 'inline-block',
        verticalAlign: 'top',
        padding: '10px'
      },
      cnpBillingCheckoutOrange: {
        fontSize: '110%',
        color: 'rgb(255, 60, 22)',
        fontWeight: 'bold'
      },
      wpwlWrapper: {
        width: '100%'
      },
      wpwlLabel: {
        width: '100%'
      },
      wpwlSupWrapper: {
        width: '100%'
      },
      wpwlGroupExpiry: {
        width: '30%',
        float: 'left'
      },
      wpwlGroupBrand: {
        width: '30%',
        float: 'left'
      },
      wpwlGroupCvv: {
        width: '68%',
        float: 'left',
        marginLeft: '2%'
      },
      wpwlGroupCardHolder: {
        clear: 'both'
      },
      wpwlSupWrapperStreet1: {
        clear: 'both',
        paddingTop: '1px'
      },
      wpwlSupWrapperState: {
        width: '32%',
        float: 'left',
        marginRight: '2%'
      },
      wpwlSupWrapperCity: {
        width: '32%',
        float: 'left',
        marginRight: '2%'
      },
      wpwlSupWrapperPostcode: {
        width: '32%',
        float: 'left'
      },
      wpwlSupWrapperCountry: {
        width: '66%'
      },
      wpwlWrapperBrand: {
        width: 'auto'
      },
      wpwlLabelBrand: {
        display: 'none'
      },
      wpwlBrand: {
        display: 'none'
      },
      wpwlGroupCardNumber: {
        width: '60%',
        float: 'left',
        fontSize: '20px'
      },
      wpwlBrandCard: {
        width: '65px'
      },
      wpwlBrandCustom: {
        margin: '0px 5px'
      },

    "@media (max-width: 1220px)": {
      subscribe_wrapper: {
        width: "90%",
      },
    },
    "@media (max-width: 1100px)": {
      featureBlock: {
        width: "30.8%",
      },
      featureDetails: {
        fontSize: "16px",
      },
      featureTitle: {
        fontSize: "16px",
        wordWrape: "anywhere",
      },
      ActiveFeatureListWrapper: {
        flexDirection: "column",
      },
    },
    "@media (max-width: 992px)": {
      featureBlock: {
        width: "47.8%",
      },
    },
    "@media (max-width: 767px)": {
      subscribe_wrapper: {
        width: "90%",
        margin: "48px auto 50px",
        padding: "30px",
      },
      featureBlock: {
        width: "100%",
      },
      closesubscribe_wrapper: {
        gap: "60px",
      },
      outersubscribe_wrapper: {
        borderRadius: "10px",
      },
      innersubscribe_wrapper: {
        padding: "12px 26.5px",
        fontSize: "10px",
        lineHeight: "15px",
      },

      pageTitle: {
        marginBottom: "30px",
      },
      subscribeButton: {
        fontSize: "12px",
        lineHeight: "22px",
        minWidth: "106px",
      },
      cancelButton: {
        fontSize: "12px",
        lineHeight: "22px",
        minWidth: "106px",
      },
      plan:{
        padding:"10px 30px",
        "& .planName":{
          fontWeight:"500"
        }
      },
      days:{
        display:"flex",
        alignItems: "center",
        gap: "20px",
        "& p":{
          fontSize:"1.2rem"
        }
      },
    },
    "@media (max-width: 640px)": {
      subscribe_wrapper: {
        width: "100%",
        margin: "48px auto 50px",
        padding: "12px"
      },
      featureBlock: {
        gap: "24px",
        flexDirection: "column",
        position: "relative",
        minHeight: "180px",
        "& img": {
          width: "30px",
          height: "30px",
          flex: "0 0 30px",
          position: "absolute",
          left: "14px",
        },
      },
      heading: {
        flexDirection: "column",
        justifyContent: "left",
        textAlign: "left",
        alignItems: "start",
        gap: "34px",
      },
      featureTitle: {
        paddingLeft: "78px",
      },
      title: {
        fontSize: "24px !important",
      },
      pageDetails: {
        fontSize: "12px !important",
      },
      footersubscribe_wrapper: {
        gap: "30px",
        marginTop: "30px",
        flexDirection: "row",
      },
      featureList: {
        gap: "16px 18px",
      },
    },
    "@media (max-width: 420px)": {
      featureTitle: {
        paddingLeft: "44px",
        fontSize: "12px !important",
      },
      featureDetails: {
        fontSize: "10px !important",
      },
    },
  });
