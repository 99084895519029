Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.methodPatch = "PATCH";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TextDetector";
exports.labelBodyText = "TextDetector Body";

exports.btnExampleTitle = "CLICK ME";

exports.requestApiContentType = "application/json";
exports.getReceivedRequestApiEndpoint = "request_management/requests/received";
exports.getSignupLinksApiEndpoint = 'account_block/accounts/sign_up_link'
exports.getReceivedRequestApiMethod = "GET";

exports.getSendRequestApiEndpoint = "request_management/requests/sent";
exports.getSendRequestApiMethod = "GET";

exports.getAllReceivedRequestApiEndpoint = "request_management/requests";
exports.getAllReceivedRequestApiMethod = "GET";

exports.createSendRequestApiEndpoint = "request_management/requests";
exports.createSendRequestApiMethod = "POST";

exports.emptyGroupIdAlert = "Please select group id";
exports.emptyRejectReasonAlert = "Please enter reason";
exports.acceptedMsgText = "Request has been accepted.";
exports.rejectedMsgText = "Request has been rejected.";
exports.deletedMsgText = "Request has been deleted.";
exports.requestSentSuccessMsg = "Request has been sent."
exports.requestUpdateSuccessMsg = "Request has been updated."

exports.updateRequestApiEndpointStart = "request_management/requests/";
exports.updateRequestApiEndpointEnd = "/review";
exports.updateRequestApiMethod = "PUT";

exports.deleteRequestApiEndpoint = "request_management/requests/";
exports.deleteRequestApiMethod = "DELETE";

exports.getGroupsApiEndpoint = "account_groups/groups";
exports.getGroupsApiMethod = "GET";

exports.getSentRequestApiEndpoint = "request_management/requests/sent";
exports.getSentRequestApiMethod = "GET";

exports.updateRequestTextApiEndpoint = "request_management/requests/";
exports.updateRequestTextApiMethod = "PUT";

exports.getUserListApiEndPoint = "/bx_block_catalogue/listings/user_list";
exports.getCurrentStageApiEndpoint = "/bx_block_request_management/deals/highest_stage_for_seller"
exports.getCHatByDeal = "/bx_block_chat/chats/get_chat";
exports.readChatAPIEndpoint= "/bx_block_chat/chats/read_messages";

exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter";

exports.getRequestsApiEndpoint = "/account_block/access_requests/total_requests_for_seller"
exports.requestActionApiEndpoint = "/account_block/access_requests"
exports.getListingFullDetails = "/bx_block_catalogue/listings/get_list"
exports.updateStartupAutoApproveEndpoint = "/bx_block_profile/start_ups/update_private_informations"
exports.updateBusinessAutoApproveEndpoint = "/bx_block_profile/business_profiles/update_private_business_information";
exports.getDealsStatusEndPoint = "/account_block/access_requests/buyer_deals"
exports.AutoSignTermsHeader = "Mutual Non Disclosure Agreement";
exports.AutoSignTermsHeaderAr = "اتفاقية عدم الإفصاح المتبادل";
const Parabody = `This Mutual Nondisclosure Agreement (this "Agreement") is made and entered into as of the later of the two dates set forth below by and between the undersigned parties (each a "Party" and collectively, the "Parties").
1. General. In connection with the consideration of a possible negotiated transaction (a "Possible Transaction") between the Parties (each such Party being hereinafter referred to as a "Company"), each Company (in its capacity as a provider of information hereunder, a "Provider") is prepared to make available to the other Company (in its capacity as a recipient of information hereunder, a recipient of information hereunder, a "Recipient") certain “Evaluation Material” (as defined in Section 2 below) in accordance with `;
exports.AutoSignTermsBody = Parabody

exports.SalePurchaseAgreementTermsHeader = "Sales Purchase Agreement";

exports.LetterOfIntentHeaderAr = "إنشاء خطاب النوايا الخاص بك";
exports.AutoSignHeaderAr = "توفير الوقت مع التوقيع التلقائي";
exports.SalePurchaseAgreementHeaderAr = "إنشاء اتفاقية شراء المبيعات والشراء";
exports.SalePurchaseAgreementTermsHeaderAr = "اتفاقية شراء المبيعات";
exports.LetterOfIntentHeader = "Create your Letter of Intent";
exports.LetterOfIntentTermsHeader = "Letter of Intent";
exports.LetterOfIntentTermsHeaderAr = "خطاب النوايا";
exports.AutoSignHeader = "Save time with auto-sign";
exports.SalePurchaseAgreementHeader = "Create your Sales Purchase Agreement";
exports.userProfileEndpoint = "/bx_block_profile/start_ups/buyer_profile"
exports.retracatEndpoint = "/account_block/access_requests/"
exports.getContentApi = "/bx_block_posts/nda_contents"
exports.getFeedbackListApi = "/bx_block_request_management/deals/all_feedback"
exports.SalePurchaseAgreementTermsBody = Parabody;
exports.LetterOfIntentTermsBody = Parabody;
exports.staticEnText= {
  listing: "Listing",
  evaluateTheCompany: "Evaluate the company",
  makeAnOffer: "Make an offer",
  handlingProposals:"Handling Proposals",
  transactionAndConclusion: "Transaction & Conclusion",
  undergoingDueDiligence:"Undergoing Due Diligence",
  facilitatePayment: "Facilitate Payment & Asset Handover",
  finalizeAcquisition:"Finalize Acquisition",
  buyers: "Buyers",
  autoApprove: "Auto Approve?",
  accepted: "Accepted",
  rejected: "Rejected",
  pending: "Pending",
  noData: "No Data",
  stage: "Stage",
  accept: "Accept",
  reject: "Reject",
  accessBuyers: "Assess Buyers",
  dueDiligence: "Due Diligence",
  terminateAccess: "Terminate Access",
  retractDeal: "Retract Deal", 
  learnMore: "Learn More",
  viewProfile: "View Profile",
  areYouSure: "Are you sure?",
  retBuyer: 'Retracting the deal will end all contact between you and the buyer, and clear all the progress you have covered so far. You will still be able to find the deal in the ‘rejected’ section',
  terSeller: "Terminating access will end all contact between you and the buyer, and clear all the progress you have covered so far. You will still be able to find the deal in the 'rejected' section",
  yias: 'Yes, I am sure',
  ntmb: 'No, take me back',
  tips: "Tips & Resources",
  videos: "Videos",
  keywords: "Key Words",
  actions: "Actions",
  documents: "Documents",
  back: "Back",
  withMe: "Shared with me",
  myMe: "Shared by me",
  uploadDocument: "Upload Document",
  upload: "Upload",
  noActionRequired: "No action required at this stage",
  proceedToNextStage: "Proceed to next stage?",
  yes: "Yes",
  finalized: "Finalized",
  pendingBuyer: "Pending Buyer to Proceed to next Stage",
pendingSeller: "Pending Seller to Proceed to next Stage",
letterOfIntent: "Letter of Intent",
send: "Send",
sent: "Sent",
loiSent: "LOI sent successfully",
 plSign: "Please sign the LOI before sending it",
 construct: "Construct your LOI and share it with the seller",
 iHaveRead: "I have read and reviewed the LOI",
 review: "Review",
 proceed: "Proceed",
 underReview: "Under review",
 loiProceed: "Review the LOI and then click on proceed to complete the stage",
 stageComplete: "Stage is complete!",
  moveToLastStage: "Now, you can move on to last step",
  contactSupport: "Contact Support",
  needHelpText: "Need help ?",
  moveToD: "Now, you can move on to Due Diligence",
  request: "Request",
  requested: "Requested",
  requestDoc :"Requested Documents",
  reviewDoc:"Review Documents",
  spa:"Sale Purchase Agreement",
  yetToInit: "Buyer has yet to initiate SPA",
  clickToProceed: "Click on proceed to complete the stage",
  signTheSpa: "Sign the SPA to send it to the Seller",
  docReqSuc: "Documents requested successfully",
  title: "Title",
  spaSent: "Sale Purchase Agreement sent successfully",
  done: "Done",
  sys: "Secure your sale",
  great: "Great job getting to the payment stage!",
  hasThe: "Has the escrow amount been released?",
  no: "No",
  hereNext: "Here's what's next:",
  checkTerms: "Check Payment Terms",
  makeSure: "Make sure all sale conditions are met.",
  use: "Use Escrow",
  safe: "For safe payment, we suggest escrow services from local banks like SNB, Riyadh Bank, SAIB, or Banque Saudi fransi",
  signDocs: "Sign Documents",
  get: "Get all sale papers and contracts signed.",
  consult: "Consult Advisors",
  have: "Have a financial advisor or lawyer review the final details.",
  paid: "Get Paid",
  once: "Once everything's cleared, ensure the buyer or the escrow bank will release your funds.",
  needHelp: "Need help? Check our",
  resourceCenter: "resource center",
  orGetItTouchWith: "or get in touch with",
  support: " support",
  congrat: "Congratulations on successfully",
failMessage: "Something went wrong",
successMessage: "Document deleted successfully",
  docUpdated: "Documents updated successfully",
addedMessage: "Document added successfully",
  acquiring: "acquiring",
  become: "Become a Buyer",
  becomeSeller: "Become a Seller",
  selling: "selling",
  yourSu: "your start up!",
  inte: "Interested in helping others navigate their journey?",
  share: "Share Your Experience",
  wn: "What's Next?",
  got: "Got another listing that you could sell?",
  buyOther: "Got another listing that you would like to buy?",
  exploreMarket: "Explore Marketplace",
  add: "Add a new listing",
  acceptAgainText: "Accept this buyer again!",
  sureToDelete: "Are you sure you want to delete document ",
  sellerConfirmationTxt: "Are you sure you want to logout from seller account?",
  buyerLogout: "Successfully logged out from buyer account",
  sellerLogout: "Successfully logged out from seller account",
  intrestedToSell: "Interested in selling an enterprise you own?",
  intr: "Interested in buying other start ups?",
  buyerConfirmationTxt: "By clicking on this you will log out and get redirected to sign up page",
  ySp: 'Yes, take me to sign up page ',
  nKh: 'No, keep me on this page ',
  turnOn: 'Turn on Auto Sign NDA?',
  cancelPopup: 'Cancel this pop-up you will direclty redirect to the Auto Sign page and please complete auto sign.'
};
exports.staticArText = {
  listing: "قائمة",
  evaluateTheCompany: "تقييم الشركة",
  accessBuyers: "تقييم المشترين",
  handlingProposals: "التعامل مع عروض الشراء",
  makeAnOffer: "قدم عرضاً",
  transactionAndConclusion: "المعاملات والخلاصة",
  undergoingDueDiligence: "العناية الواجبة",
  facilitatePayment: "تسهيل الدفع وتسليم الأصول",
  finalizeAcquisition: "الانتهاء من الاستحواذ",
  buyers: "المشترين",
  autoApprove: "الموافقة التلقائية؟",
  areYouSure: "هل أنت متأكد؟",
  retBuyer: 'سيؤدي سحب الصفقة إلى إنهاء جميع الاتصالات بينك وبين المشتري، ومسح كل التقدم الذي قمت بتغطيته حتى الآن. ستظل قادراً على العثور على الصفقة في قسم "مرفوض',
  terSeller: 'سيؤدي إنهاء الوصول إلى إنهاء جميع الاتصالات بينك وبين المشتري، ومسح كل التقدم الذي قمت بتغطيته حتى الآن. ستظل قادرًا على العثور على الصفقة في قسم "مرفوض',
  yias: 'نعم، أنا متأكد',
  ntmb: 'لا، أعدني إلى الوراء',
  accepted: "مقبول",
  rejected: "مرفوض",
  pending: "بالإنتظار",
  noData: "لايوجد بيانات",
  stage: "المرحلة",
  accept: "قبول",
  reject: "رفض",
  dueDiligence: "تخضع للعناية الواجبة",
  terminateAccess: "إنهاء الوصول",
  retractDeal: "سحب الصفقة",
  learnMore: "اعرف المزيد",
  viewProfile: "عرض الملف الشخصي",
  tips:"نصائح وموارد",
  videos: "مقاطع الفيديو",
  keywords: "الكلمات المفتاحية",
  actions: "الإجراءات",
  documents: "المستندات",
  back: "رجوع",
  withMe: "شارك معي",
  myMe: "مشاركة مني",
  uploadDocument: "تحميل المستند",
  noActionRequired: "لا يلزم اتخاذ أي إجراء في هذه المرحلة",
  proceedToNextStage: "الانتقال إلى المرحلة التالية؟",
  yes: "نعم",
  finalized: "تم الانتهاء منه",
  contactSupport: "اتصل بالدعم",
  moveToLastStage: "والآن يمكنك الانتقال إلى الخطوة الأخيرة",
  needHelpText: "تحتاج مساعدة ؟",
  pendingBuyer: "في انتظار انتقال المشتري إلى المرحلة التالية",
pendingSeller: "في انتظار البائع للمضي قدماً إلى المرحلة التالية",
letterOfIntent: "خطاب النوايا",
send: "إرسال",
sent: "تم الإرسال",
lotSent: "تم إرسال خطاب النوايا بنجاح",
plSign: "يرجى التوقيع على خطاب النوايا قبل إرساله",
 construct: "قم ببناء خطاب النوايا الخاص بك وشاركه مع البائع",
 iHaveRead: "لقد قرأت خطاب النوايا وراجعته",
 review: "المراجعة",
 underReview: "قيد المراجعة",
 loiProceed: "راجع خطاب النوايا ثم انقر فوق متابعة لإكمال المرحلة",
 proceed: "تابع",
 stageComplete: "اكتملت المرحلة!",
  moveToD: "الآن، يمكنك الانتقال إلى العناية الواجبة",
  request: "الطلب",
  upload: "التحميل",
  requested: "مطلوب",
  requestDoc :"المستندات المطلوبة",
  reviewDoc:"مراجعة الوثائق",
  successMessage: "تم حذف المستند بنجاح",
  docUpdated: "تم تحديث المستندات بنجاح",
  loiSent: "تم إرسال خطاب النوايا بنجاح",
  failMessage: "هناك خطأ ما",
  addedMessage: "تمت إضافة المستند بنجاح",
  spa:"اتفاقية البيع والشراء",
  yetToInit: "لم يشرع المشتري بعد في اتفاقية البيع بالتجزئة",
  clickToProceed: "انقر فوق متابعة لإكمال المرحلة",
  signTheSpa: "التوقيع على اتفاقية الخدمة الخاصة لإرسالها إلى البائع",
  docReqSuc: "المستندات المطلوبة بنجاح",
  title: "العنوان",
  spaSent: "تم إرسال اتفاقية البيع والشراء بنجاح",
  done: "تم",
  sys: "تأمين عملية البيع",
  great: "عمل رائع للوصول إلى مرحلة الدفع!",
  hasThe: "هل تم الإفراج عن مبلغ الضمان؟",
  hereNext: "إليك الخطوة التالية:",
  checkTerms: "التحقق من شروط الدفع",
  makeSure: "تأكد من استيفاء جميع شروط البيع.",
  use: "استخدام الضمان",
  safe: "للدفع الآمن، نقترح خدمات الضمان من البنوك المحلية مثل البنك السعودي الفرنسي أو بنك الرياض أو البنك السعودي الدولي أو البنك السعودي الفرنسي",
  no: "لا يوجد",
  signDocs: "توقيع المستندات",
  get: "احصل على جميع أوراق البيع والعقود الموقعة.",
  consult: "استشر المستشارين",
  have: "اطلب من مستشار مالي أو محامٍ مراجعة التفاصيل النهائية.",
  paid: "احصل على أجر",
  once: "بمجرد أن يتم تخليص كل شيء، تأكد من أن المشتري أو بنك الضمان سيفرج عن أموالك.",
  needHelp: "هل تحتاج إلى مساعدة؟ راجع موقعنا",
  resourceCenter: "مركز الموارد",
  orGetItTouchWith: "أو تواصل معنا مع",
  support: " الدعم",
  congrat: "تهانينا على النجاح",
  acquiring: "الحصول على",
  selling: "البيع",
  yourSu: "بدايتك!",
  inte: "هل أنت مهتم بمساعدة الآخرين على الإبحار في رحلتهم؟",
  share: "شارك تجربتك",
  wn: "ما التالي؟",
  got: "هل لديك قائمة أخرى يمكنك بيعها؟",
  exploreMarket: "اكتشف السوق",
  buyOther: "هل لديك قائمة أخرى ترغب في شرائها؟",
  becomeSeller: "كن بائعًا",
  add: "إضافة قائمة جديدة",
  intrestedToSell: "هل أنت مهتم ببيع مؤسسة تملكها؟",
  buyerLogout: "تم تسجيل الخروج من حساب المشتري بنجاح",
  sellerLogout: "تم تسجيل الخروج من حساب البائع بنجاح",
  sellerConfirmationTxt: "هل أنت متأكد أنك تريد تسجيل الخروج من حساب البائع؟",
  intr: "هل أنت مهتم بشراء شركات ناشئة أخرى؟",
  acceptAgainText: "قبول هذا المشتري مرة أخرى!",
  sureToDelete: "هل أنت متأكد أنك تريد حذف المستند ",
  become: "كن مشترياً",
  buyerConfirmationTxt: "بالنقر على هذا سيتم تسجيل خروجك وإعادة توجيهك إلى صفحة التسجيل",
  ySp: 'نعم، خذني إلى صفحة التسجيل',
  nKh: 'لا، أبقني على هذه الصفحة',
  turnOn: 'قم بتشغيل التوقيع التلقائي على اتفاقية عدم الإفشاء؟',
  cancelPopup: 'إلغاء هذه النافذة المنبثقة ستتم إعادة توجيهك مباشرةً إلى صفحة التوقيع التلقائي ويرجى إكمال التوقيع التلقائي.',
  clientNamear: 'اسم العميل',
  clientBioar: 'السيرة الذاتية للعميل'
};
exports.buyer = "buyer";
exports.endSign = "/-";
exports.httpGetMethod = "GET";
exports.getClientDataEndPoint = "bx_block_profile/start_ups/buyer_profile_with_id?id={clientID}";
exports.clientName = "Client Name";
exports.clientBio = "Client's Bio";
exports.revisedPriceEndPoint = "bx_block_request_management/deals/{DealId}/handling_proposals/update_proposal";
exports.getRevisedPriceEndPoint = "bx_block_request_management/deals/{DealId}/handling_proposals";
exports.deleteDocumentEndPoint = "bx_block_request_management/deals/destroy_document?document_id={docId}&access_request_id={accessReq}";
exports.sellPurchaseText = "Sell Purchase Agreement";
exports.loiText = "Letter Of Intent";
exports.deleteDiligenceEndPoint = "bx_block_request_management/deals/destroy_document?document_id={docId}&id={dealId}";
exports.disclaimerText = "This Share Purchase Agreement (SPA) template is provided solely for training and educational purposes. This document is not intended to constitute legal advice and does not create an attorney-client relationship. The parties using this template must seek the counsel of a qualified legal professional to ensure the document's compliance with applicable laws and to finalize any transaction. The provider of this template disclaims any liability for actions taken based on its contents, and users are advised that this document may not reflect the latest legal standards or practices. Use of this template is at the user's own risk, and professional legal consultation is strongly recommended to address specific legal issues.";
exports.arDisclaimerText = "تم توفير نموذج الاتفاقية البيع والشراء لأغراض التدريب والتعليم فقط. لا يهدف هذا المستند إلى تقديم المشورة القانونية ولا ينشئ علاقة محامٍ مع عميل. يجب على الأطراف التي تستخدم هذا النموذج طلب المشورة من محترف قانوني مؤهل لضمان امتثال المستند للقوانين اللازمة وإتمام أي معاملة. يخلي مزود هذا النموذج من أي مسؤولية عن الإجراءات المتخذة بناءً على المحتوى الموجود في الإتفاقية، وننصح المستخدمين بأن هذا المستند قد لا يعكس أحدث المعايير أو الممارسات القانونية. يكون استخدام هذا النموذج على مسؤولية المستخدم الخاصة، ويوصى بشدة بالاستعانة باستشارة قانونية مهنية لمعالجة قضايا قانونية محددة في عملية البيع أو الإستحواذ.";
exports.disclaimerHead = "Disclaimer!";
exports.arDisclaimerHead = "خلاء المسؤولية!";
exports.acknowledgeDiscText = "Acknowledge the Disclaimer";
exports.arAcknowledgeDiscText = "الاعتراف بإخلاء المسؤولية";
exports.acknowledgeText = "I Acknowledge";
exports.arAcknowledgeText = "أعترف";
exports.customFormTxt = {
  signature: "Signature",
  signingAs: "Signing as",
  fName: "First Name :",
  lName: "Last Name :",
  role: "Role :",
  save: "Save",
  revisedPrice: "Revised Asking Price :",
  intialPrice: "Initial Asking Price : SAR ",
  clear: "Clear",
  companyName: "Company Name :",
  note: "Please note: Disagreeing with the terms will terminate the current process.",
  back: "Back",
  likeToProceed: "Would you like to proceed?",
  agree: "Agree",
  terminate: "Yes, Terminate Process",
  goBack: "No, Go Back",
  aboutToDisagree: "You're About to Disagree with the Terms",
  ndaSuccessText: "Access requested & NDA updated sucessfully",
  ndaUpdated: "NDA updated successfully",
  failMsg: "Something went wrong",
  spaCreated: "Sale purchase agreement created successfully",
  loiSuccessMsg: "Letter of intent created successfully",
  disAgree: "Disagree"
};
exports.customFormTxtAr = {
  signature: "توقيع",
  signingAs: "التوقيع باسم",
  fName: "الاسم الأول :",
  lName: "الاسم الأخير :",
  role: "الدور :",
  save: "حفظ",
  intialPrice: "سعر الطلب المبدئي : ر.س ",
  revisedPrice: "السعر المنقح المطلوب :",
  note: "يُرجى ملاحظة: عدم الموافقة على الشروط سيؤدي إلى إنهاء العملية الحالية.",
  back: "رجوع",
  companyName: "اسم الشركة :",
  likeToProceed: "هل ترغب في المتابعة؟",
  agree: "موافق",
  terminate: "نعم، إنهاء العملية",
  goBack: "لا، ارجع للخلف",
  aboutToDisagree: "أنت على وشك عدم الموافقة على الشروط",
  disAgree: "غير موافق",
  ndaSuccessText: "تم طلب الوصول وتحديث اتفاقية عدم الإفشاء بنجاح",
  loiSuccessMsg: "تم إنشاء خطاب النوايا بنجاح",
  spaCreated: "تم إنشاء اتفاقية شراء المبيعات بنجاح",
  ndaUpdated: "تم تحديث اتفاقية عدم الإفصاح بنجاح",
  failMsg: "حدث خطأ ما",
  clear: "مسح"
};
exports.thankyouMsg = "Thank you for your feedback! It's now pending review and will be posted once approved by our team";
exports.thankyouMsgAr = "شكراً لك على ملاحظاتك! إنها الآن في انتظار المراجعة وسيتم نشرها بمجرد موافقة فريقنا عليها";
exports.clearText = "Clear";
exports.clearArabicText = "حذف";
exports.submitText = "Submit";
exports.submitArabicText = "إرسال"

// Customizable Area End