import React from "react";

// Customizable Area Start
import {
  Avatar, Typography, Chip, Divider, Button,
  Menu, MenuItem, IconButton, Dialog
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/styles";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import AcquisitionProcessStepper from "./AcquisitionProcessStepper.web";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { conditionSort, isEmptyObject } from "../../../components/src/Helper";
import { backArrow, closeIcon} from "./assets"
import { ReportProblemOutlined } from "@material-ui/icons";

// Customizable Area End

import MyAcquisitionController, {
  PageTextKeys,
  Props,
} from "./MyAcquisitionController";

export class MyAcquisition extends MyAcquisitionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { userListData, selectedList, listRequestsData, selectedFilter } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderBlock />
        <Dialog
              data-testid="dialogue"
              aria-labelledby="simple-dialog-title"
              open={this.state.isNdaCompleted}
              className={classes.autoSignInNda}
              scroll="body"
            >
              <div className={classes.learnMoreDialogContent} >
                <ReportProblemOutlined style={{fontSize:'100px', color:'#FF7575'}}/>
                <p className={classes.headingOfdialog}>
                  {this.state.pageText.turnOn}
                </p>
                <p className={classes.learnMoreDialogDescription}>
                  {this.state.pageText.cancelPopup}
                 </p>
                
                <button
                  data-test-id="close-button"
                  className={conditionSort(this.state.language == 'en', classes.learnMoreDialogCloseButton, classes.learnMoreDialogCloseButtonAr)}
                  onClick={() =>
                    this.navigateToNdaScreens()
                  }
                >
                  <img src={closeIcon} />
                </button>
              </div>
            </Dialog>
        <div className={classes.mainContainer}>

          <div data-test-id="btnMenuOps" onClick={(event) => this.handleMenu(event.currentTarget)} className={classes.dropdownBody}>
            <Avatar />

            {isEmptyObject(selectedList) ? <div className={classes.dropdownContent} /> :
              <Typography className={classes.dropdownContentText}>{selectedList.attributes.name}</Typography>}

            <ExpandMoreRoundedIcon fontSize="large" />
          </div>
          <AcquisitionProcessStepper data-test-id="stepper" image={conditionSort(isEmptyObject(selectedList), null, selectedList.attributes.category_name)} activeStep={this.state.maxStage} myAcquisitionType={true} selectedName={conditionSort(isEmptyObject(selectedList), "", selectedList.attributes.name)}/>

          <div className={classes.sectionTwoContainer}>
            <div className={classes.sectionHeader}>
              <Typography className={classes.sectionTitle}>{this.state.pageText.buyers}</Typography>
              <div className={classes.approveCont}>
                <Typography className={classes.sectionLabel}>{this.state.pageText.autoApprove}</Typography>

                <div data-test-id="approveToggle" onClick={conditionSort(this.state.isApproveSwitch, this.handleSwitch, () => {})} className={`${classes.switchWrapper} ${conditionSort(this.state.isApproveSwitch, classes.activeSwitch, '')}`}
                style={{border: conditionSort(this.state.isApproveSwitch, "2px solid #5412FC", "2px solid #ccc")}}
                >
                  <div className={classes.switchCircle} style={{background: conditionSort(this.state.isApproveSwitch, "#5412FC", "#ccc")}} />
                </div>
              </div>
            </div>

            <div className={classes.chipWrapper}>
              {['accepted', 'rejected', 'pending'].map((strVal: string) => (
                <Chip key={strVal} onClick={() => this.handleFilterChip(strVal)}
                  className={conditionSort(this.state.selectedFilter == strVal, classes.activeChip, classes.inActiveChip)}
                  data-test-id={`${strVal}FilterBtn`}
                  variant={conditionSort(this.state.selectedFilter == strVal, 'default', 'outlined')}
                  label={this.state.pageText[strVal as PageTextKeys]} />
              ))}
            </div>

            <Divider className={classes.dividerRage} />

            <div className={classes.tableWrapper}>
              <div className={classes.tableCont}>
                {listRequestsData.length > 0 ? (listRequestsData.map((data, index: number, array: typeof data[]) => (<div key={index}>
                  <div data-test-id={`row${index}`} className={classes.tableRow} onClick={()=> {this.sellersNavigation(data.id, data.attributes.access_request_status)}}>
                    <div className={classes.tableRowSec1}>
                      <Avatar className={classes.tableProfileImg} src={data.attributes.buyer.url} />

                      <div>
                        <Typography className={classes.tableText}>{data.attributes.buyer.name}</Typography>
                        {selectedFilter == 'accepted' && <Typography className={classes.bioRage}>{data.attributes.buyer.description}</Typography>}
                      </div>

                    </div>
                    <div className={classes.tableRowMain} >
                    <div className={classes.tableRowSec1}>
                      <button id={`view-prof-${index}`} className={classes.viewProfileButton}  onClick={(event) => this.navigateTo(event, data.attributes.buyer.id)}>{this.state.pageText.viewProfile}</button>
                    </div>
                    <div className={classes.tableRowSec1}>
                      {selectedFilter != 'accepted' && (<>
                        <Button
                          onClick={() => this.checkCondtions(+data.id, 'accept',selectedList.attributes.id)}
                          data-test-id={`reqAcceptBtn-${index}`}
                            className={classes.acceptBtn}
                            startIcon={<CheckRoundedIcon
                              className={conditionSort(this.state.language == "ar", "arabicRoundIcon", "roundIcon")} />}>
                            {conditionSort(selectedFilter == "rejected", this.state.pageText.acceptAgainText, this.state.pageText.accept)}
                          </Button>
                        {selectedFilter != 'rejected' && <Button
                          data-test-id="reqRejectBtn"
                          onClick={() => this.checkCondtions(+data.id, 'reject',selectedList.attributes.id)}
                          className={classes.rejectBtn} startIcon={<CloseRoundedIcon style={{ margin: 0, ...(conditionSort(this.state.language == 'ar', { marginLeft: 8 }, { marginRight: 8 })) }} />}>{this.state.pageText.reject}</Button>}
                      </>
                      )}
                      {selectedFilter == 'accepted' && (
                        <>
                          <Button className={classes.stageBtn}>{this.state.pageText.stage} {data.attributes.deal_stage?.replace("stage", "")}</Button>
                          <IconButton>
                            <img className={classes.nextBtnLogo} style={conditionSort(this.state.language == 'en', {transform: "rotate(180deg)"}, {})} src={backArrow} />
                          </IconButton>
                        </>
                      )}
                    </div>
                        </div>
                  </div>

                  {(array.length - 1) != index && <Divider className={classes.tableDividerRage} />}
                </div>))) : <Typography style={{ marginTop: "20px" }} align="center" className={classes.dropdownContentText}>{this.state.pageText.noData}</Typography>}

              </div>
            </div>
          </div>
        </div>
        <FooterBlock />

        <Menu
          data-test-id="simple-menu"
          className={classes.menuRoot}
          anchorEl={this.state.menuAnchorEl}
          keepMounted
          open={Boolean(this.state.menuAnchorEl)}
          onClose={() => this.handleMenu(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuListProps={{
            style: menuStyle.menuProps
          }}
        >
          {userListData.map((data) => (
            <MenuItem data-test-id="menuOption" 
            onClick={() => this.handleSelectMenu(data)} 
            className={classes.menuItemRage} 
            selected={this.state.notificationId ? this.state.notificationId ===data.id :false } 
            key={data.id}>{data.attributes.name }</MenuItem>
          ))}
        </Menu>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const menuStyle = {
  menuProps: {
    maxHeight: "50vh",
  }
}
const webStyles = createStyles({
  mainContainer: {
    padding: "2rem 4rem",
    "@media (max-width:768px)":{
      padding: "8px",
    }
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
  dropdownBody: {
    display: "flex",
    alignItems: "center",
    marginTop: ".5rem",
    marginBottom: "2rem",
    padding: "0 20px",
    width: "320px",
    height: "80px",
    borderRadius: "20px",
    border: "1px solid black",
    gap: "12px",
    "@media (max-width:768px)":{
      width: "auto",
    }
  },
  dropdownContent: {
    height: "8px",
    background: "#D9D9D9",
    borderRadius: "6px",
    width: "100%"
  },
  dropdownContentText: {
    width: "100%",
    fontSize: "20px"
  },
  sectionTwoContainer: {
    "width": "100%",
    "borderRadius": "20px",
    "border": "1px solid #8C8C8C",
    boxSizing: "border-box",
    padding: "2rem",
    marginTop: "40px"
  },
  sectionTitle: {
    fontSize: '20px',
  },
  sectionLabel: {
    fontSize: '18px',
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  switchWrapper: {
    height: "32px",
    width: "72px",
    boxSizing: "border-box",
    borderRadius: "16px",
    padding: "3px"
  },
  activeSwitch: {
    display: 'flex',
    justifyContent: 'end'
  },
  switchCircle: {
    width: "22px",
    height: "22px",
    borderRadius: "50%"
  },
  approveCont: {
    "display": "flex",
    "alignItems": "center",
    "gap": "10px"
  },
  chipWrapper: {
    marginTop: "1rem",
    display: "flex",
    gap: "1rem"
  },
  inActiveChip: {
    width: "120px",
    height: "36px",
    borderRadius: "12px",
    color: "#5412FC",
    border: "1px solid #5412FC",
    fontSize: "18px",
    fontWeight: 500,
    textTransform: "capitalize"
  },
  activeChip: {
    width: "120px",
    textTransform: "capitalize",
    fontWeight: 700,
    height: "36px",
    borderRadius: "12px",
    color: "#fff",
    background: "#5412FC !important",
    fontSize: "18px"
  },
  dividerRage: {
    background: "#8C8C8C",
    height: "1px",
    marginTop: "20px"
  },
  tableWrapper: {
    boxSizing: "border-box",
    padding: "0 50px",
    width: "100%",
    "@media (max-width:768px)":{
      padding: "0px",
    }
  },
  tableCont: {
    width: "100%"
  },
  tableRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.2rem",
    "@media (max-width:768px)":{
      flexDirection: "column"
    }
  },
  tableProfileImg: {
    height: "56px",
    width: "56px",
    "@media (max-width:768px)":{
      height: "24px",
    width: "24px",
    }
  },
  tableText: {
    fontSize: "20px",
    fontWeight: 700
  },
  tableRowMain: {
    display: 'flex', gap: '50px',
    "@media (max-width:768px)":{
      gap: "0px",
      justifyContent: "space-between"
    }
  },
  tableRowSec1: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    "@media (max-width:768px)":{
      gap: "8px"
    }
  },
  viewProfileButton: {
    background: "transparent",
    color: "#5412FC",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "24px",
    border: "none",
    textDecoration: "underline",
    cursor: 'pointer',
    whiteSpace: "nowrap",
    "@media (max-width:768px)":{
      fontSize: "12px",
    }
  },
  acceptBtn: {
    minWidth: "160px",
    "height": "40px",
    "borderRadius": "12px",
    "border": "2px solid #10BFB8",
    fontSize: "20px",
    fontWeight: 500,
    color: "#10BFB8",
    "@media (max-width:768px)":{
      fontSize: "14px",
      width: "fit-content",
      minWidth: "20px",
    },
    "& .roundIcon": {
      margin: 0,
      marginRight: 8,
      "@media (max-width:768px)":{
        marginRight: 0,
      }
    },
    "& .arabicRoundIcon": {
      margin: 0,
      marginLeft: 8,
      "@media (max-width:768px)":{
        marginLeft: 0,
      }
    },
    textTransform: "none"
  },
  rejectBtn: {
    "width": "160px",
    "height": "40px",
    "borderRadius": "12px",
    "border": "2px solid #FF7575",
    fontSize: "20px",
    fontWeight: 500,
    color: "#FF7575",
    textTransform: "none",
    
    "@media (max-width:768px)":{
      fontSize: "14px",
      width: "fit-content",
      minWidth: "20px",
    }
  },
  stageBtn: {
    "width": "160px",
    "height": "40px",
    "borderRadius": "12px",
    "border": "2px solid #10BFB8",
    fontSize: "18px",
    fontWeight: 700,
    color: "#10BFB8",
    background: "#00F8A233",
    textTransform: "none"
  },
  tableDividerRage: {
    background: "#8C8C8C",
    height: "1px",
    marginTop: "25px"
  },
  menuRoot: {
    marginTop: "65px"
  },
  menuItemRage: {
    width: "320px"
  },
  bioRage: {
    "fontSize": "12px",
    "fontWeight": 400,
    color: "#AAAAAA"
  },
  nextBtnLogo: {
    width: "28px",
    height: "28px"
  },
  autoSignInNda:{
    "& .MuiDialog-paper": {
      maxWidth: "512px",
      height: "440px",
      boxShadow: "none",
      position: "relative",
      padding: "0 120px",
      borderRadius: "20px",
      "@media (max-width:768px)":{
        width:'300px',
        padding:"0 40px"
      }
    },
  },
  learnMoreDialogContent: {
    display: "grid",
    justifyItems: "center",
    alignContent: "center",
    height: "100%",
  },
  learnMoreDialogCloseButton: {
    position: "absolute",
    top: 52,
    right: 52,
    background: "transparent",
    border: "none",
  },
  learnMoreDialogCloseButtonAr: {
    position: "absolute",
    top: 52,
    left: 52,
    background: "transparent",
    border: "none",
  },
  headingOfdialog:{
    fontSize:"24px",
    fontWeight:700,
  }
});

export default withStyles(webStyles)(MyAcquisition);
// Customizable Area End